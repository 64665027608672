import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import monkey from "./image/monkey trans.png";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import AppSettingsAltOutlinedIcon from "@mui/icons-material/AppSettingsAltOutlined";
import DeveloperBoardOutlinedIcon from "@mui/icons-material/DeveloperBoardOutlined";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import glLogo from "./image/logo trans.png";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import useSound from "use-sound";
import buttonHoverSound from "./image/Chimp.wav"; // Replace with the path to your sound file
import AddIcon from "@mui/icons-material/Add";
import grid from "./image/grid-01-01-01.png";

const Website = () => {
  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);
  const [windowsHeight, setWindowsHeight] = useState(window.innerHeight);
  const [focusPage, setFocusPage] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [taskInput, setTaskInput] = useState({ title: "", description: "" });
  const [invoiceInput, setInvoiceInput] = useState({
    invoice_date: "",
    client_name: "",
    invoice_number: "",
    client_contact: "",
    invoice_items: [
      {
        sku: 123678234,
        product: "Sample product 1",
        quantity: 21,
        unit_price: 23.2,
      },
      {
        sku: 123238234,
        product: "Sample product 2",
        quantity: 18,
        unit_price: 12,
      },
      {
        sku: 123674234,
        product: "Sample product 3",
        quantity: 2,
        unit_price: 9.99,
      },
    ],
  });

  const [bonusCalc, setBonusCalc] = useState({ direct: 12, pairing: 30 });
  const [heirierchy, setHeirierchy] = useState([
    {
      name: "Hillary",
      children: [
        {
          name: "Brandon",
          children: [
            { name: "Gravich", children: [] },
            { name: "Josephine", children: [] },
          ],
        },
        { name: "Josaline", children: [] },
      ],
    },
  ]);
  //   const []

  const [draggableWindows, setDraggableWindows] = useState([
    { title: "1", x: 50, y: 50, width: 500, height: 300 },
    { title: "2", x: 570, y: 50, width: 650, height: 500 },
  ]);

  const [draggingIndex, setDraggingIndex] = useState(null);
  const [dragStart, setDragStart] = useState(null);
  const [clientStart, setClientStart] = useState(null);

  const convertDateFormat2 = (inputDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate;
  };

  useEffect(() => {
    setInvoiceInput({
      ...invoiceInput,
      invoice_date: convertDateFormat2(new Date()),
    });
  }, []);

  const caseStudies = [
    {
      icon: (
        <DeveloperBoardOutlinedIcon
          style={{ color: "white", fontSize: "70px" }}
        />
      ),
      title: "Draggable Windows",
      description:
        "On one of our recent development we have developed an in-house code-space which will allow any native browser to run an OS like system to store windows setup init. Yes it might seem simple when you do it in your typical desktop, but when you try to achieve something like that inside say a dashboard of a company's SAAS platform, now that is stretching it. And we made it possible.",
    },
    {
      icon: (
        <DeveloperBoardOutlinedIcon
          style={{ color: "white", fontSize: "70px" }}
        />
      ),
      title: "Drag'n'Drop System",
      description:
        "Our case studies shows that drag'n'drop systems are very much doable on desktop web app, but where it comes to mobile and tablet developments, nothing seems to work, and thus we have ventured out to create one that does, looking back at how we approach the issue, we worked out the math required to understand touch events at a very deep level which is what is needed to receive data to calculate where is an item dragged from to where is the item dropped",
    },
    {
      icon: (
        <DeveloperBoardOutlinedIcon
          style={{ color: "white", fontSize: "70px" }}
        />
      ),
      title: "Draggable Windows",
      description:
        "On one of our recent development we have developed an in-house code-space which will allow any native browser to run an OS like system to store windows setup init. Yes it might seem simple when you do it in your typical desktop, but when you try to achieve something like that inside say a dashboard of a company's SAAS platform, now that is stretching it. And we made it possible.",
    },
    {
      icon: (
        <DeveloperBoardOutlinedIcon
          style={{ color: "white", fontSize: "70px" }}
        />
      ),
      title: "Sample 4",
      description: "Case Studies 1 is about drag'n'drop systems",
    },
  ];

  const [focusStudy, setFocusStudy] = useState(1);
  const [focusDemo, setFocusDemo] = useState(0);
  const [taskForDnd, setTaskForDnd] = useState([
    {
      id: "1823719812315",
      title: "Demo 1",
      children: [
        {
          id: "12371829319836945",
          title: "Drag me",
          description: "This is a sample task created to demonstrate a module",
        },
        {
          id: "12371829312319836945",
          title: "You can drag me",
          description: "This is a sample task created to demonstrate a module",
        },
      ],
    },
    {
      id: "18237192342812315",
      title: "Demo 2",
      children: [
        {
          id: "1237182234234319836945",
          title: "Drag me to another",
          description: "This is a sample task created to demonstrate a module",
        },
      ],
    },
  ]);

  function handleScroll(event) {
    console.log("scrolled");
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop > lastScrollTop && scrollTop + clientHeight < scrollHeight) {
      // Scrolling down within the div
      setFocusStudy(focusStudy + 1);
    } else if (scrollTop < lastScrollTop && scrollTop > 0) {
      // Scrolling up within the div
      setFocusStudy(focusStudy - 1);
    }

    setLastScrollTop(scrollTop);
  }

  const soundUrl = buttonHoverSound;
  const [play, { stop }] = useSound(soundUrl);
  const [mouseIsOver, setMouseIsOver] = useState(false);

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    console.log(result);

    const sourceIndex = taskForDnd.findIndex(
      (drop) => drop.id === result.source.droppableId
    );
    const destinationIndex = taskForDnd.findIndex(
      (drop) => drop.id === result.destination.droppableId
    );

    const updatingTaskSequence = [...taskForDnd];
    const [getItemToMove] = updatingTaskSequence[sourceIndex].children.splice(
      result.source.index,
      1
    );

    updatingTaskSequence[destinationIndex].children.splice(
      result.destination.index,
      0,
      getItemToMove
    );
  };
  // Listeners
  useEffect(() => {
    const handleResize = () => {
      setWindowsWidth(window.innerWidth);
      setWindowsHeight(window.innerHeight);
      console.log(
        "windows inner width =",
        window.innerWidth,
        window.innerHeight
      );
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HeirierchyMap = ({ child }) => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80px",
          width: "80px",
          background: "rgba(255,255,255,0.1)",
          borderRadius: "50%",
        }}
      >
        {child.name}
        <div
          style={{
            position: "absolute",
            top: 0,
            height: "8px",
            width: "8px",
            borderRadius: "50%",
            outline: "2px solid white",
            transform: "translate(0, -50%)",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            height: "8px",
            width: "8px",
            borderRadius: "50%",
            outline: "2px solid white",
            transform: "translate(0, 50%)",
          }}
        />
        <div style={{ position: "absolute", top: 140, left: -120 }}>
          {child.children[0] ? (
            <HeirierchyMap child={child.children[0]} />
          ) : null}
        </div>
        <div style={{ position: "absolute", top: 140, left: 120 }}>
          {child.children[1] ? (
            <HeirierchyMap child={child.children[1]} />
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: windowsWidth,
        height: windowsHeight,
        background: "black",
        position: "relative",
        overflow: "hidden",
        touchAction: "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          background: "rgba(40,40,40)",
          display: "flex",
          alignItems: "center",
          height: "7vh",
          zIndex: 20,
        }}
      >
        <div
          style={{
            fontSize: "16px",
            color: "white",
            padding: "15px 50px",
            fontWeight: 500,
          }}
        >
          Gavin Labs
        </div>
        <hr
          style={{
            height: "100%",
            width: "2px",
            background: "rgba(255,255,255,0.3)",
            border: "none",
            outline: "none",
          }}
        />
        <div style={{ padding: "15px 25px" }}>
          <MenuIcon
            className="hover_bar"
            style={{ fontSize: "30px", color: "white" }}
          />
        </div>
      </div>
      <img
        src={monkey}
        style={{
          width: "80vw",
          height: windowsHeight * 1.3,
          position: "absolute",
          left: -200,
          top: -120,
          objectFit: "contain",
        }}
      />

      <div
        style={{
          position: "absolute",
          right: focusPage === 0 ? 100 : -300,
          opacity: focusPage === 0 ? 1 : 0,
          zIndex: focusPage === 0 ? 15 : 1,
          top: "50%",
          transform: "translate(0,-50%)",
          fontSize: "90px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          transition: "0.4s all ease-in-out",
        }}
      >
        <div
          style={{
            display: "flex",
            color: "white",
            gap: "25px",
            fontWeight: 500,
            alignItems: "flex-end",
          }}
        >
          <div style={{ marginBottom: "8px" }}>Got an</div>
          <div style={{ color: "#f01fc9", fontWeight: 800, fontSize: "120px" }}>
            IDEA
          </div>
        </div>
        <div
          style={{
            display: "flex",
            color: "white",
            gap: "25px",
            fontWeight: 500,
            alignItems: "flex-end",
            marginTop: "-15px",
          }}
        >
          <div style={{ marginBottom: "8px" }}>We'll</div>
          <div style={{ color: "#f01fc9", fontWeight: 800, fontSize: "120px" }}>
            BUILT
          </div>
          <div style={{ marginBottom: "8px" }}>it.</div>
        </div>
        <div
          style={{
            color: "white",
            fontSize: "17px",
            width: "35vw",
            textAlign: "right",
            fontWeight: 300,
            marginTop: "10px",
            lineHeight: "24px",
          }}
        >
          Get in touch with our coding monkeys, we'll be there for you 😉, We
          make it simpler for you, we develop it in your budget, and we make it
          look pretty.
        </div>
        <div
          style={{
            fontSize: "23px",
            padding: "15px 30px",
            marginTop: "30px",
            position: "relative",
          }}
          className="contact_button"
          onMouseEnter={() => {
            play();
            setMouseIsOver(true);
          }}
          onMouseLeave={() => {
            stop();
            setMouseIsOver(false);
          }}
        >
          Contact Monkeys
          <div
            style={{
              position: "absolute",
              left: !mouseIsOver ? "55%" : "60%",
              top: !mouseIsOver ? "20%" : "-50%",
              rotate: !mouseIsOver ? "0deg" : "70deg",
              scale: !mouseIsOver ? 1 : 1.5,
              opacity: !mouseIsOver ? 0 : 1,
              transition: "0.8s all ease-out",
            }}
          >
            {"}"}
          </div>
          <div
            style={{
              position: "absolute",
              left: !mouseIsOver ? "10%" : "-15%",
              top: !mouseIsOver ? "40%" : "-20%",
              rotate: !mouseIsOver ? "0deg" : "-70deg",
              scale: !mouseIsOver ? 0.2 : 0.35,
              opacity: !mouseIsOver ? 0 : 1,
              fontSize: "15px",
              transition: "0.8s all ease-out",
            }}
          >
            {"func."}
          </div>
          <div
            style={{
              position: "absolute",
              left: !mouseIsOver ? "70%" : "85%",
              top: !mouseIsOver ? "70%" : "120%",
              rotate: !mouseIsOver ? "0deg" : "-70deg",
              scale: !mouseIsOver ? 0.2 : 0.35,
              opacity: !mouseIsOver ? 0 : 1,
              fontSize: "15px",
              transition: "0.8s all ease-out",
            }}
          >
            {"</>"}
          </div>
          <div
            style={{
              position: "absolute",
              left: !mouseIsOver ? "30%" : "15%",
              top: !mouseIsOver ? "70%" : "130%",
              rotate: !mouseIsOver ? "-30deg" : "70deg",
              scale: !mouseIsOver ? 0.2 : 0.35,
              opacity: !mouseIsOver ? 0 : 1,
              fontSize: "15px",
              transition: "0.8s all ease-out",
            }}
          >
            {"[ ]"}
          </div>
          <div
            style={{
              position: "absolute",
              left: !mouseIsOver ? "85%" : "95%",
              top: !mouseIsOver ? "40%" : "-20%",
              rotate: !mouseIsOver ? "-30deg" : "70deg",
              scale: !mouseIsOver ? 0.2 : 0.35,
              opacity: !mouseIsOver ? 0 : 1,
              fontSize: "15px",
              transition: "0.8s all ease-out",
            }}
          >
            {"const"}
          </div>
        </div>
      </div>

      <div
        style={{
          opacity: focusDemo === 3 && focusPage === 3 ? 1 : 0,
          zIndex: focusDemo === 3 && focusPage === 3 ? 20 : 1,
          transition: "0.4s all ease-in-out",
        }}
      >
        {draggableWindows.map((wind, index) => (
          <div
            key={index}
            onClick={() => {
              const updatingDraggableWindows = [...draggableWindows];
              const [draggedWindows] = updatingDraggableWindows.splice(
                index,
                1
              );
              updatingDraggableWindows.splice(
                updatingDraggableWindows.length,
                0,
                draggedWindows
              );
              setDraggableWindows(updatingDraggableWindows);
            }}
            style={{
              position: "absolute",
              borderRadius: "5px",
              top: wind.y,
              left: wind.x,
              width: wind.width,
              height: wind.height,
              background: "rgba(40,40,40)",
              fontSize: "14px",
              color: "white",
              zIndex: 20,
              boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
              opacity: draggingIndex === index ? 0.8 : 1,
              transition: "0.4s opacity ease-in-out",
            }}
          >
            <div
              draggable
              onDragStart={(e) => {
                setDraggingIndex(index);
                setDragStart({ x: wind.x, y: wind.y });
                setClientStart({ x: e.clientX, y: e.clientY });
              }}
              onDrag={(e) => {
                if (e.clientX > 0 && e.clientY > 0) {
                  const xDif = e.clientX - clientStart.x;
                  const yDif = e.clientY - clientStart.y;
                  const updatingDraggableWindows = [...draggableWindows];
                  updatingDraggableWindows[draggingIndex].x =
                    dragStart.x + xDif;
                  updatingDraggableWindows[draggingIndex].y =
                    dragStart.y + yDif;
                  setDraggableWindows(updatingDraggableWindows);
                }
              }}
              onDragEnd={(e) => {
                const updatingDraggableWindows = [...draggableWindows];
                const [draggedWindows] = updatingDraggableWindows.splice(
                  draggingIndex,
                  1
                );
                updatingDraggableWindows.splice(
                  updatingDraggableWindows.length,
                  0,
                  draggedWindows
                );
                setDraggableWindows(updatingDraggableWindows);
                setDraggingIndex(null);
                setDragStart(null);
                setClientStart(null);
              }}
              onTouchStart={(e) => {
                const touch = e.touches[0];
                setDraggingIndex(index);
                setDragStart({ x: wind.x, y: wind.y });
                setClientStart({ x: touch.clientX, y: touch.clientY });
              }}
              onTouchMove={(e) => {
                const touch = e.touches[0];
                if (touch.clientX > 0 && touch.clientY > 0) {
                  const xDif = touch.clientX - clientStart.x;
                  const yDif = touch.clientY - clientStart.y;
                  const updatingDraggableWindows = [...draggableWindows];
                  updatingDraggableWindows[draggingIndex].x =
                    dragStart.x + xDif;
                  updatingDraggableWindows[draggingIndex].y =
                    dragStart.y + yDif;
                  setDraggableWindows(updatingDraggableWindows);
                }
              }}
              onTouchEnd={(e) => {
                const updatingDraggableWindows = [...draggableWindows];
                const [draggedWindows] = updatingDraggableWindows.splice(
                  draggingIndex,
                  1
                );
                updatingDraggableWindows.splice(
                  updatingDraggableWindows.length,
                  0,
                  draggedWindows
                );
                setDraggableWindows(updatingDraggableWindows);
                setDraggingIndex(null);
                setDragStart(null);
                setClientStart(null);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              style={{
                padding: "10px 15px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "grab",
                touchAction: "none",
              }}
            >
              <div style={{ fontSize: "14px" }}>{"Windows  " + wind.title}</div>
              <div style={{ display: "flex", gap: "5px" }}>
                <div
                  className="windows_close_button"
                  onClick={(e) => {
                    e.stopPropagation();
                    const updatingWindows = [...draggableWindows];
                    updatingWindows.splice(index, 1);
                    setDraggableWindows(updatingWindows);
                  }}
                  style={{
                    width: "8px",
                    height: "8px",
                    background: "rgba(255,255,255,0.4)",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
                <div
                  className="windows_close_button"
                  onClick={(e) => {
                    e.stopPropagation();
                    const updatingWindows = [...draggableWindows];
                    updatingWindows.splice(index, 1);
                    setDraggableWindows(updatingWindows);
                  }}
                  style={{
                    width: "8px",
                    height: "8px",
                    background: "rgba(255,255,255,0.4)",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
                <div
                  className="windows_close_button"
                  onClick={(e) => {
                    e.stopPropagation();
                    const updatingWindows = [...draggableWindows];
                    updatingWindows.splice(index, 1);
                    setDraggableWindows(updatingWindows);
                  }}
                  style={{
                    width: "8px",
                    height: "8px",
                    background: "rgba(255,255,255,0.4)",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <hr
              style={{
                height: "2px",
                width: `100%`,
                background: "rgba(255,255,255,0.3)",
                border: "none",
                outline: "none",
              }}
            />
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.2,
                overflow: "hidden",
              }}
            >
              <div style={{ marginTop: "-55px" }}>
                <FilterNoneIcon style={{ fontSize: "80px" }} />
              </div>
              <div style={{ marginTop: "15px", textAlign: "center" }}>
                Sample draggable and scalable windows
              </div>
            </div>
            <div
              draggable
              onDragStart={(e) => {
                setDraggingIndex(index);
                setDragStart({ width: wind.width });
                setClientStart({ x: e.clientX });
              }}
              onDrag={(e) => {
                if (e.clientX > 0 && e.clientY > 0) {
                  const xDif = e.clientX - clientStart.x;
                  const updatingDraggableWindows = [...draggableWindows];
                  updatingDraggableWindows[draggingIndex].width =
                    dragStart.width + xDif;
                  setDraggableWindows(updatingDraggableWindows);
                }
              }}
              onDragEnd={(e) => {
                setDraggingIndex(null);
                setDragStart(null);
                setClientStart(null);
              }}
              onTouchStart={(e) => {
                const touch = e.touches[0];
                setDraggingIndex(index);
                setDragStart({ width: wind.width });
                setClientStart({ x: touch.clientX });
              }}
              onTouchMove={(e) => {
                const touch = e.touches[0];
                if (touch.clientX > 0 && touch.clientY > 0) {
                  const xDif = touch.clientX - clientStart.x;
                  const updatingDraggableWindows = [...draggableWindows];
                  updatingDraggableWindows[draggingIndex].width =
                    dragStart.width + xDif;
                  setDraggableWindows(updatingDraggableWindows);
                }
              }}
              onTouchEnd={(e) => {
                setDraggingIndex(null);
                setDragStart(null);
                setClientStart(null);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              style={{
                background: "red",
                width: "15px",
                height: "calc(100% - 10px)",
                position: "absolute",
                right: 0,
                top: 0,
                cursor: "w-resize",
                opacity: 0,
                transform: "translate(50%, 0)",
                touchAction: "none",
              }}
            />
            <div
              draggable
              onDragStart={(e) => {
                setDraggingIndex(index);
                setDragStart({ height: wind.height });
                setClientStart({ y: e.clientY });
              }}
              onDrag={(e) => {
                if (e.clientX > 0 && e.clientY > 0) {
                  const yDif = e.clientY - clientStart.y;
                  const updatingDraggableWindows = [...draggableWindows];
                  updatingDraggableWindows[draggingIndex].height =
                    dragStart.height + yDif;
                  setDraggableWindows(updatingDraggableWindows);
                }
              }}
              onDragEnd={(e) => {
                setDraggingIndex(null);
                setDragStart(null);
                setClientStart(null);
              }}
              onTouchStart={(e) => {
                const touch = e.touches[0];
                setDraggingIndex(index);
                setDragStart({ height: wind.height });
                setClientStart({ y: touch.clientY });
              }}
              onTouchMove={(e) => {
                const touch = e.touches[0];
                if (touch.clientX > 0 && touch.clientY > 0) {
                  const yDif = touch.clientY - clientStart.y;
                  const updatingDraggableWindows = [...draggableWindows];
                  updatingDraggableWindows[draggingIndex].height =
                    dragStart.height + yDif;
                  setDraggableWindows(updatingDraggableWindows);
                }
              }}
              onTouchEnd={(e) => {
                setDraggingIndex(null);
                setDragStart(null);
                setClientStart(null);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              style={{
                background: "red",
                width: "calc(100% - 10px)",
                height: "15px",
                position: "absolute",
                left: 0,
                bottom: 0,
                cursor: "n-resize",
                opacity: 0,
                transform: "translate(0, 50%)",
                touchAction: "none",
              }}
            />
            <div
              draggable
              onDragStart={(e) => {
                setDraggingIndex(index);
                setDragStart({ width: wind.width, height: wind.height });
                setClientStart({ x: e.clientX, y: e.clientY });
              }}
              onDrag={(e) => {
                if (e.clientX > 0 && e.clientY > 0) {
                  const yDif = e.clientY - clientStart.y;
                  const xDif = e.clientX - clientStart.x;
                  const updatingDraggableWindows = [...draggableWindows];
                  updatingDraggableWindows[draggingIndex].height =
                    dragStart.height + yDif;
                  updatingDraggableWindows[draggingIndex].width =
                    dragStart.width + xDif;
                  setDraggableWindows(updatingDraggableWindows);
                }
              }}
              onDragEnd={(e) => {
                setDraggingIndex(null);
                setDragStart(null);
                setClientStart(null);
              }}
              onTouchStart={(e) => {
                const touch = e.touches[0];
                setDraggingIndex(index);
                setDragStart({ width: wind.width, height: wind.height });
                setClientStart({ x: touch.clientX, y: touch.clientY });
              }}
              onTouchMove={(e) => {
                const touch = e.touches[0];
                if (touch.clientX > 0 && touch.clientY > 0) {
                  const yDif = touch.clientY - clientStart.y;
                  const xDif = touch.clientX - clientStart.x;
                  const updatingDraggableWindows = [...draggableWindows];
                  updatingDraggableWindows[draggingIndex].height =
                    dragStart.height + yDif;
                  updatingDraggableWindows[draggingIndex].width =
                    dragStart.width + xDif;
                  setDraggableWindows(updatingDraggableWindows);
                }
              }}
              onTouchEnd={(e) => {
                setDraggingIndex(null);
                setDragStart(null);
                setClientStart(null);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              style={{
                background: "red",
                width: "20px",
                height: "20px",
                position: "absolute",
                right: 0,
                bottom: 0,
                cursor: "nw-resize",
                opacity: 0,
                transform: "translate(50%, 50%)",
                touchAction: "none",
              }}
            />
          </div>
        ))}
      </div>

      <div
        style={{
          position: "absolute",
          right: focusPage === 1 ? 100 : -300,
          opacity: focusPage === 1 ? 1 : 0,
          zIndex: focusPage === 1 ? 15 : 1,
          top: "45%",
          transform: "translate(0,-50%)",
          fontSize: "90px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          transition: "0.4s all ease-in-out",
          color: "white",
        }}
      >
        {caseStudies.map((study, index) => (
          <div
            className="add_pointer"
            onClick={() => setFocusStudy(index)}
            style={{
              position: "absolute",
              right: -Math.abs((index - focusStudy) * 350),
              top: (index - focusStudy) * 250,
              transform: "translate(0, -50%)",
              width: "40vw",
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              transform:
                focusStudy === index
                  ? "translate(0, -50%) scale(1)"
                  : "translate(0, -50%) scale(0.6)",
              transition: "0.4s all ease-in-out",
              opacity: focusStudy === index ? 1 : 0.2,
              textAlign: "right",
              zIndex: 8,
            }}
          >
            <div>{study.icon}</div>
            <div style={{ fontSize: "70px" }}>{study.title}</div>
            <div
              style={{ fontSize: "18px", fontWeight: 300, marginTop: "20px" }}
            >
              {study.description}
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          position: "absolute",
          right: focusPage === 3 ? 0 : -300,
          opacity: focusPage === 3 ? 1 : 0,
          zIndex: focusPage === 3 ? 15 : 1,
          top: "50%",
          transform: "translate(0,-50%)",
          fontSize: "90px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          transition: "0.4s all ease-in-out",
          color: "white",
        }}
      >
        <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
          <div
            style={{
              width: "50vw",
              position: "relative",
              display: "flex",
              fontSize: "19px",
            }}
          >
            <div
              style={{
                width: "50vw",
                position: "absolute",
                display: "flex",
                fontSize: "19px",
                top: 0,
                transform: "translate(0, -50%)",
                opacity: focusDemo === 0 ? 1 : 0,
                zIndex: focusDemo === 0 ? 12 : 10,
                transition: "0.4s all ease-in-out",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: "rgba(40,40,40)",
                  fontSize: "19px",
                  borderRadius: "5px",
                }}
              >
                <div style={{ padding: "10px 15px" }}>Create a Task</div>
                <hr
                  style={{
                    height: "2px",
                    width: "100%",
                    background: "rgba(255,255,255,0.3)",
                    border: "none",
                    outline: "none",
                  }}
                />
                <form style={{ padding: "10px 15px" }}>
                  <div>
                    <input
                      placeholder="Create a sample task"
                      value={taskInput.title}
                      required
                      onChange={(e) =>
                        setTaskInput({ ...taskInput, title: e.target.value })
                      }
                      style={{
                        background: "transparent",
                        color: "white",
                        fontSize: "15px",
                        padding: "10px",
                        width: "15vw",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <input
                      placeholder="Type task description here"
                      value={taskInput.description}
                      required
                      onChange={(e) =>
                        setTaskInput({
                          ...taskInput,
                          description: e.target.value,
                        })
                      }
                      style={{
                        background: "transparent",
                        color: "white",
                        fontSize: "15px",
                        padding: "10px",
                        width: "15vw",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div>
                    <button
                      className="add_pointer"
                      style={{
                        padding: "8px 25px",
                        fontSize: "14px",
                        color: "rgba(40,40,40)",
                        background: "white",
                        cursor: "pointer",
                        marginTop: "10px",
                        border: "none",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        const updatingTask = [...taskForDnd];

                        updatingTask[0].children.push({
                          ...taskInput,
                          id: `${new Date()}`,
                        });

                        setTaskForDnd(updatingTask);
                        setTaskInput({ title: "", description: "" });
                      }}
                    >
                      Create task
                    </button>
                  </div>
                </form>
              </div>
              <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                {taskForDnd.map((title, index) => (
                  <div
                    key={index}
                    style={{
                      marginLeft: index !== 0 ? "1vw" : "18vw",
                      width: "calc(35vw / 2)",
                      background: "rgba(40,40,40)",
                      height: "70vh",
                      borderRadius: "5px",
                    }}
                  >
                    <div style={{ padding: "10px 15px" }}>{title.title}</div>
                    <hr
                      style={{
                        height: "2px",
                        width: "100%",
                        background: "rgba(255,255,255,0.3)",
                        border: "none",
                        outline: "none",
                      }}
                    />
                    <div style={{ padding: "15px" }}>
                      <Droppable droppableId={title.id} key={index}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              className="adjusted_scroll_bar"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "rgba(255,255,255,0.05)"
                                  : "transparent",
                                height: "calc(70vh - 75px)",
                                overflowY: "auto",
                                borderRadius: "5px",
                              }}
                            >
                              {title.children.map((child, indexs) => (
                                <Draggable
                                  key={child.id}
                                  draggableId={child.id}
                                  index={indexs}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          borderRadius: "5px",
                                          padding: 16,
                                          margin: "0 0 8px 0",
                                          minHeight: "50px",
                                          backgroundColor: snapshot.isDragging
                                            ? `rgba(255,255,255,0.1)`
                                            : "rgba(255,255,255,0.2)",
                                          color: "white",
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <div>{child.title}</div>
                                        <div
                                          style={{
                                            color: "rgba(255,255,255,0.8)",
                                            fontSize: "12px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {child.description}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                ))}
              </DragDropContext>
            </div>
            <div
              style={{
                width: "50vw",
                position: "absolute",
                display: "flex",
                fontSize: "19px",
                top: 0,
                transform: "translate(0, -50%)",
                opacity: focusDemo === 1 ? 1 : 0,
                zIndex: focusDemo === 1 ? 12 : 10,
                transition: "0.4s all ease-in-out",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: "rgba(40,40,40)",
                  fontSize: "19px",
                  borderRadius: "5px",
                }}
              >
                <div style={{ padding: "10px 15px" }}>
                  Create Sample Invoice
                </div>
                <hr
                  style={{
                    height: "2px",
                    width: "100%",
                    background: "rgba(255,255,255,0.3)",
                    border: "none",
                    outline: "none",
                  }}
                />
                <form style={{ padding: "10px 15px" }}>
                  <div>
                    <input
                      placeholder="Client name"
                      value={invoiceInput.client_name}
                      required
                      onChange={(e) =>
                        setInvoiceInput({
                          ...invoiceInput,
                          client_name: e.target.value,
                        })
                      }
                      style={{
                        background: "transparent",
                        color: "white",
                        fontSize: "15px",
                        padding: "10px",
                        width: "15vw",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <input
                      placeholder="Client contact details"
                      value={invoiceInput.client_contact}
                      required
                      onChange={(e) =>
                        setInvoiceInput({
                          ...invoiceInput,
                          client_contact: e.target.value,
                        })
                      }
                      style={{
                        background: "transparent",
                        color: "white",
                        fontSize: "15px",
                        padding: "10px",
                        width: "15vw",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </form>
              </div>
              <div
                style={{
                  marginLeft: "18vw",
                  width: "35vw",
                  height: "60vh",
                  background: "rgba(40,40,40)",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <div style={{ padding: "10px 15px" }}>
                  Your generated invoice
                </div>
                <hr
                  style={{
                    height: "2px",
                    width: "100%",
                    background: "rgba(255,255,255,0.3)",
                    border: "none",
                    outline: "none",
                  }}
                />
                <div
                  style={{
                    width: "85%",
                    marginLeft: "7.5%",
                    height: "60vh",
                    marginTop: "7%",
                    overflow: "hidden",
                    position: "relative",
                    padding: "5px",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      padding: "5px 80px",
                      background: "#f01fc9",
                      position: "absolute",
                      zIndex: 13,
                      top: 20,
                      left: -60,
                      rotate: "-35deg",
                    }}
                  >
                    Invoice
                  </div>
                  <div
                    style={{
                      background: "white",
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      zIndex: 12,
                      backgroundImage:
                        "linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.7))",
                      color: "rgba(40,40,40)",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={glLogo}
                        style={{ height: "100px", width: "100px" }}
                      />
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          width: "50%",
                        }}
                      >
                        <div>Gavin Labs Sample Invoice</div>
                        <div style={{ fontSize: "8px", fontWeight: 300 }}>
                          No.13, Jalan Angklung 33/20, Shah Alam Technology
                          Park, Section 33, 40400 Shah Alam, Selangor, Darul
                          Ehsan.
                        </div>
                        <div style={{ fontSize: "8px", fontWeight: 300 }}>
                          Gavin Labs Sample Invoice
                        </div>
                      </div>
                    </div>
                    <hr
                      style={{
                        marginLeft: "5%",
                        width: "90%",
                        height: "1px",
                        background: "grey",
                        border: "none",
                        outline: "none",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "90%",
                        marginLeft: "5%",
                        marginTop: "20px",
                        fontSize: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <div style={{ fontSize: "16px", fontWeight: 700 }}>
                          Invoice 0001
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            marginTop: "10px",
                          }}
                        >
                          <div style={{ fontWeight: 700 }}>Client name:</div>
                          <div style={{ fontWeight: 300 }}>
                            {invoiceInput.client_name
                              ? invoiceInput.client_name
                              : "Input client name"}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            marginTop: "3px",
                          }}
                        >
                          <div style={{ fontWeight: 700 }}>Date:</div>
                          <div style={{ fontWeight: 300 }}>
                            {invoiceInput.invoice_date
                              ? invoiceInput.invoice_date
                              : "Generating date"}
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            opacity: 0,
                          }}
                        >
                          Invoice 0001
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            marginTop: "10px",
                          }}
                        >
                          <div style={{ fontWeight: 700 }}>Client contact:</div>
                          <div style={{ fontWeight: 300 }}>
                            {invoiceInput.client_contact
                              ? invoiceInput.client_contact
                              : "Input client contact details"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "90%",
                        marginLeft: "5%",
                        marginTop: "25px",
                        fontSize: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <div style={{ fontSize: "12px", fontWeight: 700 }}>
                        Order items
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "90%",
                        marginLeft: "5%",
                        fontSize: "7px",
                        border: "1px solid grey",
                        color: "black",
                        background: "rgba(0,0,0,0.1)",
                      }}
                    >
                      <div style={{ width: "15%", padding: "5px 10px" }}>
                        SKU
                      </div>
                      <div style={{ width: "35%", padding: "5px 10px" }}>
                        PRODUCT
                      </div>
                      <div style={{ width: "10%", padding: "5px 10px" }}>
                        QTY
                      </div>
                      <div style={{ width: "15%", padding: "5px 10px" }}>
                        PRICE
                      </div>
                      <div style={{ width: "15%", padding: "5px 10px" }}>
                        TOTAL
                      </div>
                    </div>
                    {invoiceInput.invoice_items.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "90%",
                          marginLeft: "5%",
                          fontSize: "8px",
                          border: "1px solid grey",
                        }}
                      >
                        <div style={{ width: "15%", padding: "5px 10px" }}>
                          {item.sku}
                        </div>
                        <div style={{ width: "35%", padding: "5px 10px" }}>
                          {item.product}
                        </div>
                        <div style={{ width: "10%", padding: "5px 10px" }}>
                          {item.quantity}
                        </div>
                        <div style={{ width: "15%", padding: "5px 10px" }}>
                          {"USD " +
                            parseFloat(item.unit_price).toLocaleString(
                              "en-US",
                              {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                        </div>
                        <div style={{ width: "15%", padding: "5px 10px" }}>
                          {"USD " +
                            (
                              parseFloat(item.quantity) *
                              parseFloat(item.unit_price)
                            ).toLocaleString("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginTop: "10px",
                        fontSize: "8px",
                        marginLeft: "5%",
                      }}
                    >
                      <div style={{ fontWeight: 700 }}>Remarks:</div>
                      <div style={{ fontWeight: 300 }}>
                        This is a sample invoice meant to be used for the
                        purpose of a demonstration
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "50vw",
                position: "absolute",
                display: "flex",
                fontSize: "19px",
                top: 0,
                transform: "translate(0, -50%)",
                opacity: focusDemo === 2 ? 1 : 0,
                zIndex: focusDemo === 2 ? 12 : 10,
                transition: "0.4s all ease-in-out",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  background: "rgba(40,40,40)",
                  fontSize: "19px",
                  borderRadius: "5px",
                }}
              >
                <div style={{ padding: "10px 15px" }}>Bonus Calculations</div>
                <hr
                  style={{
                    height: "2px",
                    width: "100%",
                    background: "rgba(255,255,255,0.3)",
                    border: "none",
                    outline: "none",
                  }}
                />
                <form style={{ padding: "10px 15px" }}>
                  <div>
                    <input
                      placeholder="Client name"
                      type="number"
                      min={0}
                      max={100}
                      value={bonusCalc.direct}
                      required
                      onChange={(e) =>
                        setBonusCalc({
                          ...bonusCalc,
                          direct: e.target.value,
                        })
                      }
                      style={{
                        background: "transparent",
                        color: "white",
                        fontSize: "15px",
                        padding: "10px",
                        width: "15vw",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <input
                      placeholder="Client contact details"
                      type="number"
                      min={0}
                      max={100}
                      value={bonusCalc.pairing}
                      required
                      onChange={(e) =>
                        setBonusCalc({
                          ...bonusCalc,
                          pairing: e.target.value,
                        })
                      }
                      style={{
                        background: "transparent",
                        color: "white",
                        fontSize: "15px",
                        padding: "10px",
                        width: "15vw",
                        outline: "none",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </form>
              </div>

              <div
                style={{
                  marginLeft: "18vw",
                  width: "35vw",
                  height: "60vh",
                  background: "rgba(40,40,40)",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <div style={{ padding: "10px 15px" }}>Heirierchy</div>
                <hr
                  style={{
                    height: "2px",
                    width: "100%",
                    background: "rgba(255,255,255,0.3)",
                    border: "none",
                    outline: "none",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div
                    draggable
                    style={{
                      position: "absolute",
                      top: -2000,
                      left: -2000,
                      width: 6000,
                      height: 6000,
                      //   background: "red",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        opacity: 0.2,
                        objectFit: "cover",
                      }}
                      src={grid}
                    />
                    {heirierchy.map((item, index) => (
                      <div
                        style={{
                          position: "absolute",
                          top: 2050,
                          left: 2250,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80px",
                          width: "80px",
                          background: "rgba(255,255,255,0.1)",
                          borderRadius: "50%",
                        }}
                        key={index}
                      >
                        {item.name}
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            height: "8px",
                            width: "8px",
                            borderRadius: "50%",
                            outline: "2px solid white",
                            transform: "translate(0, 50%)",
                          }}
                        />
                        <div
                          style={{ position: "absolute", top: 140, left: -120 }}
                        >
                          {item.children[0] ? (
                            <HeirierchyMap child={item.children[0]} />
                          ) : null}
                        </div>
                        <div
                          style={{ position: "absolute", top: 140, left: 120 }}
                        >
                          {item.children[1] ? (
                            <HeirierchyMap child={item.children[1]} />
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "50vw",
                position: "absolute",
                display: "flex",
                fontSize: "19px",
                top: 0,
                transform: "translate(0, -50%)",
                opacity: focusDemo === 3 ? 1 : 0,
                zIndex: focusDemo === 3 ? 12 : 10,
                transition: "0.4s all ease-in-out",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="add_pointer"
                onClick={() =>
                  setDraggableWindows([
                    ...draggableWindows,
                    {
                      title: `${draggableWindows.length + 1}`,
                      x: 50 * draggableWindows.length + 50,
                      y: 50 * draggableWindows.length + 50,
                      width: 500,
                      height: 300,
                    },
                  ])
                }
                style={{
                  width: "15vw",
                  height: "15vw",
                  background: "rgba(255,255,255,0.1)",
                  marginRight: "10vw",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <AddIcon
                  style={{ fontSize: "80px", color: "rgba(255,255,255,0.3)" }}
                />
                <div style={{ color: "rgba(255,255,255,0.3)" }}>
                  Create more windows
                </div>
              </div>
            </div>
          </div>
          <div style={{ fontSize: "14px" }}>
            <div
              className="showroom_sidebar"
              style={{
                border:
                  focusDemo === 0 ? "1px solid #f01fc9" : "1px solid white",
                borderRadius: "10px 10px 0px 0px",
                rotate: "-90deg",
                color: focusDemo === 0 ? "black" : "white",
                background: focusDemo === 0 ? "#f01fc9" : "transparent",
                fontWeight: focusDemo === 0 ? 700 : 400,
              }}
              onClick={() => setFocusDemo(0)}
            >
              <div
                style={{
                  width: "120px",
                  textAlign: "center",
                  padding: "10px 0px",
                  height: "35px",
                }}
              >
                DND Task
              </div>
            </div>
            <div
              className="showroom_sidebar"
              style={{
                borderRadius: "10px 10px 0px 0px",
                rotate: "-90deg",
                marginTop: "88px",
                border:
                  focusDemo === 1 ? "1px solid #f01fc9" : "1px solid white",
                color: focusDemo === 1 ? "black" : "white",
                background: focusDemo === 1 ? "#f01fc9" : "transparent",
                fontWeight: focusDemo === 1 ? 700 : 400,
              }}
              onClick={() => setFocusDemo(1)}
            >
              <div
                style={{
                  width: "120px",
                  textAlign: "center",
                  padding: "10px 0px",
                  height: "35px",
                }}
              >
                Doc. Gen
              </div>
            </div>
            {/* <div
              className="showroom_sidebar"
              style={{
                borderRadius: "10px 10px 0px 0px",
                rotate: "-90deg",
                marginTop: "88px",
                border:
                  focusDemo === 2 ? "1px solid #f01fc9" : "1px solid white",
                color: focusDemo === 2 ? "black" : "white",
                background: focusDemo === 2 ? "#f01fc9" : "transparent",
                fontWeight: focusDemo === 2 ? 700 : 400,
              }}
              onClick={() => setFocusDemo(2)}
            >
              <div
                style={{
                  width: "120px",
                  textAlign: "center",
                  padding: "10px 0px",
                  height: "35px",
                }}
              >
                Bonus Calc.
              </div>
            </div> */}
            <div
              className="showroom_sidebar"
              style={{
                border: "1px solid white",
                borderRadius: "10px 10px 0px 0px",
                rotate: "-90deg",
                marginTop: "88px",
                border:
                  focusDemo === 3 ? "1px solid #f01fc9" : "1px solid white",
                color: focusDemo === 3 ? "black" : "white",
                background: focusDemo === 3 ? "#f01fc9" : "transparent",
                fontWeight: focusDemo === 3 ? 700 : 400,
              }}
              onClick={() => setFocusDemo(3)}
            >
              <div
                style={{
                  width: "120px",
                  textAlign: "center",
                  padding: "10px 0px",
                  height: "35px",
                }}
              >
                DND Windows
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: 55,
          bottom: 40,
          color: "white",
          fontWeight: 300,
          display: "flex",
          gap: "55px",
        }}
      >
        <div
          className="hover_bar"
          onClick={() => setFocusPage(0)}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <DeveloperBoardOutlinedIcon />
          Home
        </div>
        <div
          className="hover_bar"
          onClick={() => setFocusPage(1)}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <DeveloperBoardOutlinedIcon />
          Our Case Studies
        </div>
        {/* <div
          className="hover_bar"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <AppSettingsAltOutlinedIcon />
          Our Past Projects
        </div> */}
        <div
          className="hover_bar"
          onClick={() => setFocusPage(3)}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <IntegrationInstructionsOutlinedIcon />
          Development showroom
        </div>
      </div>
    </div>
  );
};

export default Website;
