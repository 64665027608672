import React from "react";
import "./App.css";

const Sample = () => {
  const sampleData = [
    {
      user: "Roy",
      surname: " Mc",
    },
    {
      user: "Jon",
      surname: " asd",
    },
    {
      user: "David",
      surname: " asd",
    },
    {
      user: "TIm",
      surname: " sad",
    },
    {
      user: "TIm",
      surname: " sad",
    },
    {
      user: "TIm",
      surname: " sad",
    },
    {
      user: "TIm",
      surname: " sad",
    },
  ];

  return (
    <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          fontSize: "80px",
          fontWeight: 700,
          color: "rgba(50,50,50)",
          textAlign: "left",
          paddingLeft: "15%",
          lineHeight: "80px",
        }}
      >
        {" "}
        <div>
          This is
          <br />
          Smart Lend
        </div>
        <div
          style={{
            fontSize: "18px",
            fontWeight: 400,
            width: "70%",
            marginTop: "20px",
            lineHeight: "20px",
          }}
        >
          lorem ipsum is a sample ggenerated text for the purpose of a filler
          text.
        </div>
        <div
          style={{
            padding: "8px 25px",
            borderRadius: "40px",
            background: "lightblue",
            border: "none",
            outline: "none",
            fontSize: "18px",
            marginTop: "20px",
            lineHeight: "20px",
            color: "white",
          }}
        >
          Learn more
        </div>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="rotate"
          style={{
            width: "700px",
            height: "700px",
            borderRadius: "20px",
            background: "lightblue",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {sampleData.map((data, index) => (
            <div key={index}>
              {data.user}. {data.surname}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sample;
