import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import "./App.css";
import { overrideThemeVariables } from "ui-neumorphism";
import {
  Card,
  CardContent,
  CardAction,
  Button,
  ProgressCircular,
  Dialog,
  ProgressLinear,
  TextField,
  Divider,
} from "ui-neumorphism";
import "ui-neumorphism/dist/index.css";
import grid from "./image/grid-01-01-01.png";
import TerminalIcon from "@mui/icons-material/Terminal";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ShuffleOnOutlinedIcon from "@mui/icons-material/ShuffleOnOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import * as Icons from "@mui/icons-material"; // Replace 'fa' with the desired icon library

import CloseIcon from "@mui/icons-material/Close";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import MessageIcon from "@mui/icons-material/Message";
import SendIcon from "@mui/icons-material/Send";
import PaymentIcon from "@mui/icons-material/Payment";
import SavingsIcon from "@mui/icons-material/Savings";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { cloneDeep, isEqual } from "lodash";

const color = [
  {
    color: "#3f8a53",
  },
  {
    color: "#3f748a",
  },
  {
    color: "#3f8a5b",
  },
  {
    color: "#4f3f8a",
  },
  {
    color: "#853f8a",
  },
  {
    color: "#8a3f60",
  },
  {
    color: "#8a3f3f",
  },
  {
    color: "#588a3f",
  },
  {
    color: "#8a623f",
  },
  {
    color: "#808a3f",
  },
];

const Homepage = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [screenWidth, setScreenWidth] = useState("1920");
  const scrollContainerRef = useRef();
  const [key, setKey] = useState(0);
  const [touching, setTouching] = useState(false);

  const divRef = useRef(null);
  const [deletable, setDeletable] = useState(false);
  const iconLibrary = Icons;
  const iconNames = Object.keys(iconLibrary);
  const [iconsSearchTerm, setIconSearchTerm] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const projectId = urlParams.get("idpdv");
  const isAdmin = urlParams.get("admin");

  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isAdmin === "ANGELdark123") {
      setAdmin(true);
    }
  }, []);

  const [createModuleModalIsOpen, setCreateModuleModalIsOpen] = useState(false);
  const [createTransationsModalIsOpen, setCreateTransationsModalIsOpen] =
    useState(false);

  const mainFlowGap = 1000;
  const flowChartGap = 200;
  const flowSecondChartGap = 140;
  const flowThirdChartGap = 150;
  const flowForthChartGap = 130;
  const flowFifthChartGap = 225;

  const [buildStageTimeline, setBuildStageTimeline] = useState([]);
  const [buildModules, setBuildModules] = useState([]);
  const [appJourneryEditable, setAppJourneyEditable] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [moduleTimeline, setModuleTimeline] = useState([]);
  const [messages, setMessages] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [messageToSend, setMessageToSend] = useState("");

  overrideThemeVariables({
    "--light-bg": "#e4ebf5",
    "--light-bg-dark-shadow": "rgba(190,190,190)",
    "--light-bg-light-shadow": "white",
    "--dark-bg": "#292E35",
    "--dark-bg-dark-shadow": "#21252a",
    "--dark-bg-light-shadow": "#313740",
    "--primary": "#8672FB",
    "--primary-dark": "#4526f9",
    "--primary-light": "#c7befd",
  });
  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);
  const [windowsHeight, setWindowsHeight] = useState(window.innerHeight);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startDrag, setStartDrag] = useState(null);
  const [drag, setDrag] = useState(null);
  const [position, setPosition] = useState({ x: -500, y: -500 });

  const [currency, setCurrency] = useState("USD");

  const [projectName, setProjectName] = useState("");

  const [clientName, setClientName] = useState("");

  const [projectObjective, setProjectObjective] = useState("");

  const convertDateFormat2 = (inputDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate;
  };

  const [appFlowChart, setAppFlowChart] = useState([
    {
      title: "First Module",
      description: "This the first sample module",
      children: [],
    },
  ]);

  useEffect(() => {
    if (appFlowChart.length === 0) {
      setAppFlowChart([
        {
          title: "First Module",
          description: "This the first sample module",
          children: [],
        },
      ]);
    }
  }, [appFlowChart]);

  const [inputModules, setInputModules] = useState({
    icon: "",
    title: "",
    description: "",
    toughness: 0,
  });

  const [inputTransaction, setInputTransaction] = useState({
    amount: "",
    date: new Date(),
    transaction_notes: "",
  });

  const sendMessageToDB = async (messageToSend) => {
    try {
      const response = await fetch(
        `https://ww1.premelo.com/api/update_gl_message/${projectId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: projectId,
            message: JSON.stringify(messageToSend),
          }),
        }
      );
      const json = await response.json();
      setMessages(JSON.parse(json.data[0].message));
    } catch (error) {
      console.log(error);
    }
  };

  // Functions
  const getColor = (index) => {
    const colorIndex = index % color.length;
    return color[colorIndex].color;
  };

  const PullIconForDisplay1 = (icon, index) => {
    if (icon) {
      const Icon = iconLibrary[icon];
      const BackupIcon = iconLibrary["FaFacebookSquare"];
      if (Icon) {
        return <Icon style={{ fontSize: "100px", color: "rgba(65,65,65)" }} />;
      } else {
        return (
          <BackupIcon style={{ fontSize: "100px", color: "rgba(65,65,65)" }} />
        ); // Icon not found
      }
    } else {
      return null; // No icon chosen
    }
  };

  const PullIconForDisplay = (icon, index) => {
    if (icon) {
      const Icon = iconLibrary[icon];
      const BackupIcon = iconLibrary["FaFacebookSquare"];
      if (Icon) {
        return <Icon style={{ fontSize: "55px", color: getColor(index) }} />;
      } else {
        return (
          <BackupIcon style={{ fontSize: "55px", color: getColor(index) }} />
        ); // Icon not found
      }
    } else {
      return null; // No icon chosen
    }
  };

  const pushToDB = async () => {
    const dataToPush = projectId
      ? {
          id: projectId,
          project_name: projectName,
          client_name: clientName,
          project_objective: projectObjective,
          app_flow_chart: JSON.stringify(appFlowChart),
          build_modules: JSON.stringify(buildModules),
          message: JSON.stringify(messages),
          transactions: JSON.stringify(transactions),
          start_date: startDate,
          currency: currency,
        }
      : {
          project_name: projectName,
          client_name: clientName,
          project_objective: projectObjective,
          app_flow_chart: JSON.stringify(appFlowChart),
          build_modules: JSON.stringify(buildModules),
          message: JSON.stringify(messages),
          transactions: JSON.stringify(transactions),
          start_date: startDate,
          currency: currency,
        };
    // console.log(projectId);

    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/create_update_gl_project",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToPush),
        }
      );

      const json = await response.json();
      if (json.success) {
        urlParams.set("idpdv", json.data.id);
        const updatedSearchString = urlParams.toString();
        const newUrl = `${window.location.pathname}?${updatedSearchString}`;
        window.history.pushState({ path: newUrl }, "", newUrl);

        setProjectName(json.data.project_name);
        setClientName(json.data.client_name);
        setProjectObjective(json.data.project_objective);
        setAppFlowChart(JSON.parse(json.data.app_flow_chart));
        setBuildModules(JSON.parse(json.data.build_modules));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalDevelopmentCost = (buildModules) => {
    let price = 0;
    for (const mod of buildModules) {
      price += (parseFloat(mod.toughness) / 100) * 20000;
    }

    return price;
  };

  const getTotalDevelopmentTime = (buildModules) => {
    let weeks = 0;
    for (const mod of buildModules) {
      weeks += parseFloat(mod.timeline);
    }

    return weeks;
  };

  const formatDuration = (duration) => {
    const weeks = Math.floor(duration);
    const days = Math.round((duration - weeks) * 7); // Convert remaining days from decimal
    let result = "";

    if (weeks > 0) {
      result += `${weeks} ${weeks === 1 ? "week" : "weeks"}`;
    }

    if (days > 0) {
      result += `${result.length > 0 ? " " : ""}${days} ${
        days === 1 ? "day" : "days"
      }`;
    }

    return result;
  };

  const formatDate = (inputDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-GB",
      options
    );
    return formattedDate;
  };

  const convertDateFormat = (inputDate) => {
    const [day, month, year] = inputDate.split("/");
    const formattedDate = new Date(
      `${year}-${month}-${day}`
    ).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return formattedDate;
  };

  const calculateEndDate = (startDate, durations, index) => {
    const start = new Date(startDate);
    const end = new Date(start);

    for (let i = 0; i <= index; i++) {
      end.setDate(end.getDate() + parseFloat(durations[i].duration) * 7);
    }

    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedEndDate = end.toLocaleDateString("en-GB", options);
    return formattedEndDate;
  };

  // Listeners
  useEffect(() => {
    const handleResize = () => {
      setWindowsWidth(window.innerWidth);
      setWindowsHeight(window.innerHeight);
      console.log(
        "windows inner width =",
        window.innerWidth,
        window.innerHeight
      );
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Access the current property of the ref to get the DOM element
    const scrollContainer = scrollContainerRef.current;

    // Set scrollTop to the maximum scroll height
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  }, [messages]); // Run the effect only once on mount

  useEffect(() => {
    let totalModule = Math.ceil(buildModules.length / 4);
    let totalWeeks = 0;
    console.log(buildModules);

    for (const mod of buildModules) {
      totalWeeks += parseFloat(mod.timeline);
    }

    const softClone = [...buildModules];
    let newTimeline = [];

    for (let i = 0; i < softClone.length; i++) {
      const groupIndex = Math.floor(i / totalModule);

      if (!newTimeline[groupIndex]) {
        newTimeline[groupIndex] = [{ ...softClone[i] }];
      } else {
        newTimeline[groupIndex].push({ ...softClone[i] });
      }
    }

    setModuleTimeline(newTimeline);

    let buildTimeLine = newTimeline.map((group) => {
      const groupDuration = group.reduce(
        (acc, mod) => acc + parseFloat(mod.timeline),
        0
      );
      return { duration: groupDuration.toFixed(1) };
    });
    setBuildStageTimeline(buildTimeLine);

    console.log(buildTimeLine);
  }, [buildModules]);

  useEffect(() => {
    const getProjectDetails = async () => {
      try {
        const response = await fetch(
          `https://ww1.premelo.com/api/get_gl_project_details/${projectId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const json = await response.json();
        console.log(json);
        if (json.success) {
          setProjectName(json.data.project_name);
          setClientName(json.data.client_name);
          setProjectObjective(json.data.project_objective);
          if (json.data.app_flow_chart) {
            setAppFlowChart(JSON.parse(json.data.app_flow_chart));
          }
          if (json.data.build_modules) {
            setBuildModules(JSON.parse(json.data.build_modules));
          }
          if (json.data.message) {
            setMessages(JSON.parse(json.data.message));
          }
          if (json.data.transactions) {
            setTransactions(JSON.parse(json.data.transactions));
          }
          setStartDate(json.data.start_date);
          setCurrency(json.data.currency);
        }
        if (!json.success) {
          setProjectName("");
          setClientName("");
          setProjectObjective("");
          setAppFlowChart([
            {
              title: "First Module",
              description: "This the first sample module",
              children: [],
            },
          ]);
          setBuildModules([]);
          setMessages([]);
          setTransactions([]);
          setStartDate(new Date());
          setCurrency("USD");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getProjectDetails();
  }, []);

  const retrieveMessage = async () => {
    try {
      const response = await fetch(
        `https://ww1.premelo.com/api/retrieve_gl_message/${projectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.success === true) {
        setMessages((prevMessages) => {
          // Check if the message has changed before updating state

          if (!isEqual(JSON.parse(json.data), prevMessages)) {
            return JSON.parse(json.data);
          }
          return prevMessages;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      retrieveMessage();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  // Render
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#e4ebf5",
        overflowY: "hidden",
        overflowX: "hidden",
        padding: "30px 0px 0px 0px",
      }}
    >
      <div
        style={{
          width: "90%",
          fontSize: screenWidth < 800 ? "25px" : "30px",
          marginTop: "30px",
          color: "rgba(50,50,50)",
          fontWeight: "700",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {!appJourneryEditable ? (
            projectName
          ) : (
            <TextField
              className="project_name_text_field"
              style={{ margin: "5px 0px 5px 0px" }}
              width="400px"
              label="Project Objectives"
              value={projectName || ""}
              onChange={(e) => {
                setProjectName(e.value);
              }}
            />
          )}
        </div>
        {windowsWidth <= 1000 ? null : admin ? (
          <div style={{ display: "flex", gap: "20px" }}>
            <Button
              onClick={() => {
                setDeletable(!deletable);
              }}
              className="button_small"
              style={{
                borderRadius: "5px",
              }}
            >
              {deletable ? "Turn Off" : "Turn On"}
            </Button>
            <Button
              onClick={() => {
                if (appJourneryEditable) {
                  setAppJourneyEditable(!appJourneryEditable);
                  pushToDB();
                } else {
                  setAppJourneyEditable(!appJourneryEditable);
                }
              }}
              className="button_small"
              style={{
                borderRadius: "5px",
              }}
            >
              {appJourneryEditable ? "SAVE" : "EDIT"}
            </Button>
          </div>
        ) : null}
        {/* <div>}
          <Button
            onClick={() => {}}
            className="button_small"
            style={{ borderRadius: "8px" }}
          ></Button>
        </div> */}
      </div>
      <Card
        inset
        style={{
          width: "90%",
          marginTop: "20px",
          borderRadius: "10px",
          padding: "30px",
        }}
      >
        <div style={{ color: "rgba(180,180,180)", fontSize: "14px" }}>
          Client name:
        </div>
        <div
          style={{
            color: "rgba(100,100,100)",
            fontSize: "15px",
            marginTop: "5px",
          }}
        >
          {!appJourneryEditable ? (
            clientName
          ) : (
            <TextField
              className="project_details_text_field"
              style={{ margin: "5px 0px 5px 0px" }}
              width="400px"
              label="Client name"
              value={clientName || ""}
              onChange={(e) => {
                setClientName(e.value);
              }}
            />
          )}
        </div>
        <div
          style={{
            color: "rgba(180,180,180)",
            fontSize: "14px",
            marginTop: "20px",
          }}
        >
          Development Objectives:
        </div>
        <div
          style={{
            color: "rgba(100,100,100)",
            fontSize: "15px",
            marginTop: "5px",
          }}
        >
          {!appJourneryEditable ? (
            projectObjective
          ) : (
            <TextField
              className="project_details_text_field"
              style={{ margin: "5px 0px 5px 0px" }}
              width="400px"
              label="Project Objectives"
              value={projectObjective || ""}
              onChange={(e) => {
                setProjectObjective(e.value);
              }}
            />
          )}
        </div>
        <div
          style={{
            color: "rgba(180,180,180)",
            fontSize: "14px",
            marginTop: "20px",
          }}
        >
          Development start date:
        </div>
        <div
          style={{
            color: "rgba(100,100,100)",
            fontSize: "15px",
            marginTop: "5px",
          }}
        >
          {!appJourneryEditable ? (
            formatDate(startDate)
          ) : (
            <input
              type="date"
              className="project_details_text_field"
              style={{
                margin: "5px 0px 5px 0px",
                padding: "5px 10px",
                border: "none",
                outline: "none",
                background: "rgb(228, 235, 245)",
                color: "rgba(100,100,100)",
              }}
              width="400px"
              label="Project start date"
              value={startDate || ""}
              onChange={(e) => {
                console.log(e.target.value);
                setStartDate(e.target.value);
              }}
            />
          )}
        </div>
        {appJourneryEditable ? (
          <>
            <div
              style={{
                color: "rgba(180,180,180)",
                fontSize: "14px",
                marginTop: "20px",
              }}
            >
              Currency:
            </div>
            <div
              style={{
                color: "rgba(100,100,100)",
                fontSize: "15px",
                marginTop: "5px",
              }}
            >
              {!appJourneryEditable ? (
                currency
              ) : (
                <TextField
                  className="project_details_text_field"
                  style={{ margin: "5px 0px 5px 0px" }}
                  width="400px"
                  label="Client name"
                  value={currency || ""}
                  onChange={(e) => {
                    setCurrency(e.value);
                  }}
                />
              )}
            </div>
          </>
        ) : null}
      </Card>
      {windowsWidth <= 800 ? null : (
        <>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "90%",
            }}
          >
            <div
              style={{
                fontSize: "28px",
                color: "rgba(50,50,50)",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TerminalIcon style={{ fontSize: "40px", marginRight: "15px" }} />
              App Journey
            </div>
          </div>
          <Card
            inset
            style={{
              width: "90%",
              height: "750px",
              borderRadius: "10px",
              marginTop: "20px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div
              className="draggable_grid"
              draggable
              onDragStart={(e) => {
                const dragImages = new Image();
                dragImages.src = ""; // Set an empty source
                e.dataTransfer.setDragImage(dragImages, 0, 0);
                setDrag({ x: e.clientX, y: e.clientY });
                setStartDrag({ x: position.x, y: position.y });
              }}
              onTouchStart={(e) => {
                const touch = e.touches[0];
                setTouching(true);
                setDrag({ x: touch.clientX, y: touch.clientY });
                setStartDrag({ x: position.x, y: position.y });
              }}
              onTouchMove={(e) => {
                const touch = e.touches[0];

                if (touch.clientX !== 0 && touch.clientY !== 0) {
                  const updatingPosition = { ...position };
                  if (
                    touch.clientX - drag.x + startDrag.x < 0 &&
                    touch.clientX - drag.x + startDrag.x >
                      -6000 + windowsWidth * 0.9
                  ) {
                    updatingPosition.x = touch.clientX - drag.x + startDrag.x;
                  }
                  if (
                    touch.clientY - drag.y + startDrag.y < 0 &&
                    touch.clientY - drag.y + startDrag.y > -3000
                  ) {
                    updatingPosition.y = touch.clientY - drag.y + startDrag.y;
                  }
                  setPosition(updatingPosition);
                }
              }}
              onTouchEnd={(e) => {
                setDrag(null);
                setStartDrag(null);
                setTouching(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrag={(e) => {
                if (e.clientX !== 0 && e.clientY !== 0) {
                  const updatingPosition = { ...position };
                  if (
                    e.clientX - drag.x + startDrag.x < 0 &&
                    e.clientX - drag.x + startDrag.x >
                      -6000 + windowsWidth * 0.9
                  ) {
                    updatingPosition.x = e.clientX - drag.x + startDrag.x;
                  }
                  if (
                    e.clientY - drag.y + startDrag.y < 0 &&
                    e.clientY - drag.y + startDrag.y > -3000
                  ) {
                    updatingPosition.y = e.clientY - drag.y + startDrag.y;
                  }
                  setPosition(updatingPosition);
                }
              }}
              onDragEnd={(e) => {
                setDrag(null);
                setStartDrag(null);
              }}
              style={{
                width: "6000px",
                height: "6000px",
                position: "absolute",
                top: position.y,
                left: position.x,
                cursor: "move",
                touchAction: "none",
              }}
            >
              <img
                src={grid}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  opacity: "0.1",
                }}
              />
              {appFlowChart.map((first, index) => (
                <Card
                  className="main_hover_appear"
                  key={index}
                  style={{
                    position: "absolute",
                    left: 600,
                    top: 820 + index * mainFlowGap,
                    padding: "20px 40px",
                    borderRadius: "10px",
                    color: "rgba(100,100,100)",
                    zIndex: 5,
                    height: "110px",
                    width: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ fontWeight: 600, fontSize: "20px" }}>
                    {!appJourneryEditable ? (
                      first.title
                    ) : (
                      <TextField
                        className="journey_text_field"
                        style={{ margin: "15px 0px 0px 0px" }}
                        label="Title"
                        value={appFlowChart[index].title}
                        onChange={(e) => {
                          const updatingFlowChart = [...appFlowChart];
                          updatingFlowChart[index].title = e.value;
                          setAppFlowChart(updatingFlowChart);
                        }}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {!appJourneryEditable ? (
                      first.description
                    ) : (
                      <TextField
                        className="journey_text_field_description"
                        style={{ margin: "-10px 0px 0px 0px" }}
                        label="Description"
                        value={appFlowChart[index].description}
                        onChange={(e) => {
                          const updatingFlowChart = [...appFlowChart];
                          updatingFlowChart[index].description = e.value;
                          setAppFlowChart(updatingFlowChart);
                        }}
                      />
                    )}
                  </div>
                  <div
                    className="add_pointer"
                    onClick={() => {
                      if (appJourneryEditable) {
                        const updatingFlowChart = [...appFlowChart];
                        const newFlow = {
                          title: "",
                          description: "",
                          children: [],
                        };
                        updatingFlowChart[index].children.push(newFlow);
                        setAppFlowChart(updatingFlowChart);
                      }
                    }}
                    style={{
                      width: "8px",
                      height: "8px",
                      zIndex: 10,
                      position: "absolute",
                      right: -4,
                      top: "calc(50% - 4px)",
                      background: "grey",
                      borderRadius: "50%",
                      outline: "3px solid white",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                    }}
                  />
                  {first.children.map((child, indexes) => (
                    <>
                      <Card
                        className="main_hover_appear"
                        key={indexes}
                        style={{
                          position: "absolute",
                          padding: "20px 40px",
                          borderRadius: "10px",
                          color: "rgba(100,100,100)",
                          zIndex: 5,
                          left: 500,
                          height: "110px",
                          width: "300px",
                          top:
                            indexes * flowChartGap -
                            (first.children.length * flowChartGap) / 2 +
                            flowChartGap / 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {deletable ? (
                          <div
                            onClick={() => {
                              const updatingFlowChart = [...appFlowChart];
                              updatingFlowChart[index].children.splice(
                                indexes,
                                1
                              );
                              setAppFlowChart(updatingFlowChart);
                            }}
                            className="hover_appear"
                            style={{
                              position: "absolute",
                              opacity: 0,
                              transition: "0.3s all ease-in-out",
                              right: 4,
                              top: 10,
                              zIndex: 6,
                            }}
                          >
                            <CloseIcon />
                          </div>
                        ) : null}
                        <div style={{ fontWeight: 600, fontSize: "20px" }}>
                          {!appJourneryEditable ? (
                            child.title
                          ) : (
                            <TextField
                              className="journey_text_field"
                              style={{ margin: "15px 0px 0px 0px" }}
                              label="Title"
                              value={
                                appFlowChart[index].children[indexes].title
                              }
                              onChange={(e) => {
                                const updatingFlowChart = [...appFlowChart];
                                updatingFlowChart[index].children[
                                  indexes
                                ].title = e.value;
                                setAppFlowChart(updatingFlowChart);
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "12px",
                            marginTop: "5px",
                          }}
                        >
                          {!appJourneryEditable ? (
                            child.description
                          ) : (
                            <TextField
                              className="journey_text_field_description"
                              style={{ margin: "-10px 0px 0px 0px" }}
                              label="Description"
                              value={
                                appFlowChart[index].children[indexes]
                                  .description
                              }
                              onChange={(e) => {
                                const updatingFlowChart = [...appFlowChart];
                                updatingFlowChart[index].children[
                                  indexes
                                ].description = e.value;
                                setAppFlowChart(updatingFlowChart);
                              }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            width: "8px",
                            height: "8px",
                            zIndex: 6,
                            position: "absolute",
                            left: -4,
                            top: "calc(50% - 4px)",
                            background: "grey",
                            borderRadius: "50%",
                            outline: "3px solid white",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                          }}
                        />
                        <div
                          className="add_pointer"
                          onClick={() => {
                            if (appJourneryEditable) {
                              const updatingFlowChart = [...appFlowChart];
                              const newFlow = {
                                title: "",
                                description: "",
                                children: [],
                              };
                              updatingFlowChart[index].children[
                                indexes
                              ].children.push(newFlow);
                              setAppFlowChart(updatingFlowChart);
                            }
                          }}
                          style={{
                            width: "8px",
                            height: "8px",
                            zIndex: 10,
                            position: "absolute",
                            right: -4,
                            top: "calc(50% - 4px)",
                            background: "grey",
                            borderRadius: "50%",
                            outline: "3px solid white",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                          }}
                        />
                        {child.children.map((infant, indexs) => (
                          <>
                            <Card
                              className="main_hover_appear"
                              key={indexs}
                              style={{
                                position: "absolute",
                                padding: "20px 40px",
                                borderRadius: "10px",
                                color: "rgba(100,100,100)",
                                zIndex: 5,
                                left: 500,
                                height: "110px",
                                width: "300px",
                                top:
                                  indexs * flowSecondChartGap -
                                  (child.children.length * flowSecondChartGap) /
                                    2 +
                                  flowSecondChartGap / 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              {deletable ? (
                                <div
                                  onClick={() => {
                                    const updatingFlowChart = [...appFlowChart];
                                    updatingFlowChart[index].children[
                                      indexes
                                    ].children.splice(indexs, 1);
                                    setAppFlowChart(updatingFlowChart);
                                  }}
                                  className="hover_appear"
                                  style={{
                                    position: "absolute",
                                    opacity: 0,
                                    transition: "0.3s all ease-in-out",
                                    right: 4,
                                    top: 10,
                                    zIndex: 6,
                                  }}
                                >
                                  <CloseIcon />
                                </div>
                              ) : null}
                              <div
                                style={{ fontWeight: 600, fontSize: "20px" }}
                              >
                                {!appJourneryEditable ? (
                                  infant.title
                                ) : (
                                  <TextField
                                    className="journey_text_field"
                                    style={{ margin: "15px 0px 0px 0px" }}
                                    label="Title"
                                    value={
                                      appFlowChart[index].children[indexes]
                                        .children[indexs].title
                                    }
                                    onChange={(e) => {
                                      const updatingFlowChart = [
                                        ...appFlowChart,
                                      ];
                                      updatingFlowChart[index].children[
                                        indexes
                                      ].children[indexs].title = e.value;
                                      setAppFlowChart(updatingFlowChart);
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  marginTop: "5px",
                                }}
                              >
                                {!appJourneryEditable ? (
                                  infant.description
                                ) : (
                                  <TextField
                                    className="journey_text_field_description"
                                    style={{ margin: "-10px 0px 0px 0px" }}
                                    label="Description"
                                    value={
                                      appFlowChart[index].children[indexes]
                                        .children[indexs].description
                                    }
                                    onChange={(e) => {
                                      const updatingFlowChart = [
                                        ...appFlowChart,
                                      ];
                                      updatingFlowChart[index].children[
                                        indexes
                                      ].children[indexs].description = e.value;
                                      setAppFlowChart(updatingFlowChart);
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  width: "8px",
                                  height: "8px",
                                  zIndex: 6,
                                  position: "absolute",
                                  left: -4,
                                  top: "calc(50% - 4px)",
                                  background: "grey",
                                  borderRadius: "50%",
                                  outline: "3px solid white",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                                }}
                              />
                              <div
                                className="add_pointer"
                                onClick={() => {
                                  if (appJourneryEditable) {
                                    const updatingFlowChart = [...appFlowChart];
                                    const newFlow = {
                                      title: "",
                                      description: "",
                                      children: [],
                                    };
                                    updatingFlowChart[index].children[
                                      indexes
                                    ].children[indexs].children.push(newFlow);
                                    setAppFlowChart(updatingFlowChart);
                                  }
                                }}
                                style={{
                                  width: "8px",
                                  height: "8px",
                                  zIndex: 10,
                                  position: "absolute",
                                  right: -4,
                                  top: "calc(50% - 4px)",
                                  background: "grey",
                                  borderRadius: "50%",
                                  outline: "3px solid white",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                                }}
                              />
                              {infant.children.map((unborn, indexxes) => (
                                <>
                                  <Card
                                    className="main_hover_appear"
                                    key={indexxes}
                                    style={{
                                      position: "absolute",
                                      padding: "20px 40px",
                                      borderRadius: "10px",
                                      color: "rgba(100,100,100)",
                                      zIndex: 5,
                                      left: 500,
                                      height: "110px",
                                      width: "300px",
                                      top:
                                        indexxes * flowThirdChartGap -
                                        (infant.children.length *
                                          flowThirdChartGap) /
                                          2 +
                                        flowThirdChartGap / 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {deletable ? (
                                      <div
                                        onClick={() => {
                                          const updatingFlowChart = [
                                            ...appFlowChart,
                                          ];
                                          updatingFlowChart[index].children[
                                            indexes
                                          ].children[indexs].children.splice(
                                            indexxes,
                                            1
                                          );
                                          setAppFlowChart(updatingFlowChart);
                                        }}
                                        className="hover_appear"
                                        style={{
                                          position: "absolute",
                                          opacity: 0,
                                          transition: "0.3s all ease-in-out",
                                          right: 4,
                                          top: 10,
                                          zIndex: 6,
                                        }}
                                      >
                                        <CloseIcon />
                                      </div>
                                    ) : null}
                                    <div
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "20px",
                                      }}
                                    >
                                      {!appJourneryEditable ? (
                                        unborn.title
                                      ) : (
                                        <TextField
                                          className="journey_text_field"
                                          style={{ margin: "15px 0px 0px 0px" }}
                                          label="Title"
                                          value={
                                            appFlowChart[index].children[
                                              indexes
                                            ].children[indexs].children[
                                              indexxes
                                            ].title
                                          }
                                          onChange={(e) => {
                                            const updatingFlowChart = [
                                              ...appFlowChart,
                                            ];
                                            updatingFlowChart[index].children[
                                              indexes
                                            ].children[indexs].children[
                                              indexxes
                                            ].title = e.value;
                                            setAppFlowChart(updatingFlowChart);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {!appJourneryEditable ? (
                                        unborn.description
                                      ) : (
                                        <TextField
                                          className="journey_text_field_description"
                                          style={{
                                            margin: "-10px 0px 0px 0px",
                                          }}
                                          label="Description"
                                          value={
                                            appFlowChart[index].children[
                                              indexes
                                            ].children[indexs].children[
                                              indexxes
                                            ].description
                                          }
                                          onChange={(e) => {
                                            const updatingFlowChart = [
                                              ...appFlowChart,
                                            ];
                                            updatingFlowChart[index].children[
                                              indexes
                                            ].children[indexs].children[
                                              indexxes
                                            ].description = e.value;
                                            setAppFlowChart(updatingFlowChart);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        width: "8px",
                                        height: "8px",
                                        zIndex: 6,
                                        position: "absolute",
                                        left: -4,
                                        top: "calc(50% - 4px)",
                                        background: "grey",
                                        borderRadius: "50%",
                                        outline: "3px solid white",
                                        boxShadow:
                                          "0px 0px 10px rgba(0,0,0,0.3)",
                                      }}
                                    />
                                    <div
                                      className="add_pointer"
                                      onClick={() => {
                                        if (appJourneryEditable) {
                                          const updatingFlowChart = [
                                            ...appFlowChart,
                                          ];
                                          const newFlow = {
                                            title: "",
                                            description: "",
                                            children: [],
                                          };
                                          updatingFlowChart[index].children[
                                            indexes
                                          ].children[indexs].children[
                                            indexxes
                                          ].children.push(newFlow);
                                          setAppFlowChart(updatingFlowChart);
                                        }
                                      }}
                                      style={{
                                        width: "8px",
                                        height: "8px",
                                        zIndex: 10,
                                        position: "absolute",
                                        right: -4,
                                        top: "calc(50% - 4px)",
                                        background: "grey",
                                        borderRadius: "50%",
                                        outline: "3px solid white",
                                        boxShadow:
                                          "0px 0px 10px rgba(0,0,0,0.3)",
                                      }}
                                    />
                                    {unborn.children.map((sperm, indexss) => (
                                      <>
                                        <Card
                                          className="main_hover_appear"
                                          key={indexss}
                                          style={{
                                            position: "absolute",
                                            padding: "20px 40px",
                                            borderRadius: "10px",
                                            color: "rgba(100,100,100)",
                                            zIndex: 5,
                                            left: 500,
                                            height: "110px",
                                            width: "300px",
                                            top:
                                              indexss * flowForthChartGap -
                                              (unborn.children.length *
                                                flowForthChartGap) /
                                                2 +
                                              flowForthChartGap / 2,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {deletable ? (
                                            <div
                                              onClick={() => {
                                                const updatingFlowChart = [
                                                  ...appFlowChart,
                                                ];
                                                updatingFlowChart[
                                                  index
                                                ].children[indexes].children[
                                                  indexs
                                                ].children[
                                                  indexxes
                                                ].children.splice(indexss, 1);
                                                setAppFlowChart(
                                                  updatingFlowChart
                                                );
                                              }}
                                              className="hover_appear"
                                              style={{
                                                position: "absolute",
                                                opacity: 0,
                                                transition:
                                                  "0.3s all ease-in-out",
                                                right: 4,
                                                top: 10,
                                                zIndex: 6,
                                              }}
                                            >
                                              <CloseIcon />
                                            </div>
                                          ) : null}
                                          <div
                                            style={{
                                              fontWeight: 600,
                                              fontSize: "20px",
                                            }}
                                          >
                                            {!appJourneryEditable ? (
                                              sperm.title
                                            ) : (
                                              <TextField
                                                className="journey_text_field"
                                                style={{
                                                  margin: "15px 0px 0px 0px",
                                                }}
                                                label="Title"
                                                value={
                                                  appFlowChart[index].children[
                                                    indexes
                                                  ].children[indexs].children[
                                                    indexxes
                                                  ].children[indexss].title
                                                }
                                                onChange={(e) => {
                                                  const updatingFlowChart = [
                                                    ...appFlowChart,
                                                  ];
                                                  updatingFlowChart[
                                                    index
                                                  ].children[indexes].children[
                                                    indexs
                                                  ].children[indexxes].children[
                                                    indexss
                                                  ].title = e.value;
                                                  setAppFlowChart(
                                                    updatingFlowChart
                                                  );
                                                }}
                                              />
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              marginTop: "5px",
                                            }}
                                          >
                                            {!appJourneryEditable ? (
                                              sperm.description
                                            ) : (
                                              <TextField
                                                className="journey_text_field_description"
                                                style={{
                                                  margin: "-10px 0px 0px 0px",
                                                }}
                                                label="Description"
                                                value={
                                                  appFlowChart[index].children[
                                                    indexes
                                                  ].children[indexs].children[
                                                    indexxes
                                                  ].children[indexss]
                                                    .description
                                                }
                                                onChange={(e) => {
                                                  const updatingFlowChart = [
                                                    ...appFlowChart,
                                                  ];
                                                  updatingFlowChart[
                                                    index
                                                  ].children[indexes].children[
                                                    indexs
                                                  ].children[indexxes].children[
                                                    indexss
                                                  ].description = e.value;
                                                  setAppFlowChart(
                                                    updatingFlowChart
                                                  );
                                                }}
                                              />
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              width: "8px",
                                              height: "8px",
                                              zIndex: 6,
                                              position: "absolute",
                                              left: -4,
                                              top: "calc(50% - 4px)",
                                              background: "grey",
                                              borderRadius: "50%",
                                              outline: "3px solid white",
                                              boxShadow:
                                                "0px 0px 10px rgba(0,0,0,0.3)",
                                            }}
                                          />
                                          <div
                                            className="add_pointer"
                                            onClick={() => {
                                              if (appJourneryEditable) {
                                                const updatingFlowChart = [
                                                  ...appFlowChart,
                                                ];
                                                const newFlow = {
                                                  title: "",
                                                  description: "",
                                                  children: [],
                                                };
                                                updatingFlowChart[
                                                  index
                                                ].children[indexes].children[
                                                  indexs
                                                ].children[indexxes].children[
                                                  indexss
                                                ].children.push(newFlow);
                                                setAppFlowChart(
                                                  updatingFlowChart
                                                );
                                              }
                                            }}
                                            style={{
                                              width: "8px",
                                              height: "8px",
                                              zIndex: 10,
                                              position: "absolute",
                                              right: -4,
                                              top: "calc(50% - 4px)",
                                              background: "grey",
                                              borderRadius: "50%",
                                              outline: "3px solid white",
                                              boxShadow:
                                                "0px 0px 10px rgba(0,0,0,0.3)",
                                            }}
                                          />
                                          {sperm.children.map(
                                            (cell, indexsss) => (
                                              <>
                                                <Card
                                                  className="main_hover_appear"
                                                  key={indexsss}
                                                  style={{
                                                    position: "absolute",
                                                    padding: "20px 40px",
                                                    borderRadius: "10px",
                                                    color: "rgba(100,100,100)",
                                                    zIndex: 5,
                                                    left: 500,
                                                    height: "110px",
                                                    width: "300px",
                                                    top:
                                                      indexsss *
                                                        flowFifthChartGap -
                                                      (sperm.children.length *
                                                        flowFifthChartGap) /
                                                        2 +
                                                      flowFifthChartGap / 2,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  {deletable ? (
                                                    <div
                                                      onClick={() => {
                                                        const updatingFlowChart =
                                                          [...appFlowChart];
                                                        updatingFlowChart[
                                                          index
                                                        ].children[
                                                          indexes
                                                        ].children[
                                                          indexs
                                                        ].children[
                                                          indexxes
                                                        ].children[
                                                          indexss
                                                        ].children.splice(
                                                          indexsss,
                                                          1
                                                        );
                                                        setAppFlowChart(
                                                          updatingFlowChart
                                                        );
                                                      }}
                                                      className="hover_appear"
                                                      style={{
                                                        position: "absolute",
                                                        opacity: 0,
                                                        transition:
                                                          "0.3s all ease-in-out",
                                                        right: 4,
                                                        top: 10,
                                                        zIndex: 6,
                                                      }}
                                                    >
                                                      <CloseIcon />
                                                    </div>
                                                  ) : null}
                                                  <div
                                                    style={{
                                                      fontWeight: 600,
                                                      fontSize: "20px",
                                                    }}
                                                  >
                                                    {!appJourneryEditable ? (
                                                      cell.title
                                                    ) : (
                                                      <TextField
                                                        className="journey_text_field"
                                                        style={{
                                                          margin:
                                                            "15px 0px 0px 0px",
                                                        }}
                                                        label="Title"
                                                        value={
                                                          appFlowChart[index]
                                                            .children[indexes]
                                                            .children[indexs]
                                                            .children[indexxes]
                                                            .children[indexss]
                                                            .children[indexsss]
                                                            .title
                                                        }
                                                        onChange={(e) => {
                                                          const updatingFlowChart =
                                                            [...appFlowChart];
                                                          updatingFlowChart[
                                                            index
                                                          ].children[
                                                            indexes
                                                          ].children[
                                                            indexs
                                                          ].children[
                                                            indexxes
                                                          ].children[
                                                            indexss
                                                          ].children[
                                                            indexsss
                                                          ].title = e.value;
                                                          setAppFlowChart(
                                                            updatingFlowChart
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontWeight: 400,
                                                      fontSize: "12px",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    {!appJourneryEditable ? (
                                                      cell.description
                                                    ) : (
                                                      <TextField
                                                        className="journey_text_field_description"
                                                        style={{
                                                          margin:
                                                            "-10px 0px 0px 0px",
                                                        }}
                                                        label="Description"
                                                        value={
                                                          appFlowChart[index]
                                                            .children[indexes]
                                                            .children[indexs]
                                                            .children[indexxes]
                                                            .children[indexss]
                                                            .children[indexsss]
                                                            .description
                                                        }
                                                        onChange={(e) => {
                                                          const updatingFlowChart =
                                                            [...appFlowChart];
                                                          updatingFlowChart[
                                                            index
                                                          ].children[
                                                            indexes
                                                          ].children[
                                                            indexs
                                                          ].children[
                                                            indexxes
                                                          ].children[
                                                            indexss
                                                          ].children[
                                                            indexsss
                                                          ].description =
                                                            e.value;
                                                          setAppFlowChart(
                                                            updatingFlowChart
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: "8px",
                                                      height: "8px",
                                                      zIndex: 6,
                                                      position: "absolute",
                                                      left: -4,
                                                      top: "calc(50% - 4px)",
                                                      background: "grey",
                                                      borderRadius: "50%",
                                                      outline:
                                                        "3px solid white",
                                                      boxShadow:
                                                        "0px 0px 10px rgba(0,0,0,0.3)",
                                                    }}
                                                  />
                                                </Card>
                                                <svg
                                                  style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: 300,
                                                    overflow: "visible",
                                                    zIndex: 8,
                                                    width: "200px",
                                                  }}
                                                >
                                                  <path
                                                    d={`M 0 0 C 150 0 50 ${
                                                      indexsss *
                                                        flowFifthChartGap -
                                                      (sperm.children.length *
                                                        flowFifthChartGap) /
                                                        2 +
                                                      flowFifthChartGap / 2
                                                    } 200 ${
                                                      indexsss *
                                                        flowFifthChartGap -
                                                      (sperm.children.length *
                                                        flowFifthChartGap) /
                                                        2 +
                                                      flowFifthChartGap / 2
                                                    }`}
                                                    fill="transparent"
                                                    stroke="grey"
                                                    strokeWidth={4}
                                                  />
                                                </svg>
                                              </>
                                            )
                                          )}
                                        </Card>
                                        <svg
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: 300,
                                            overflow: "visible",
                                            zIndex: 8,
                                            width: "200px",
                                          }}
                                        >
                                          <path
                                            d={`M 0 0 C 150 0 50 ${
                                              indexss * flowForthChartGap -
                                              (unborn.children.length *
                                                flowForthChartGap) /
                                                2 +
                                              flowForthChartGap / 2
                                            } 200 ${
                                              indexss * flowForthChartGap -
                                              (unborn.children.length *
                                                flowForthChartGap) /
                                                2 +
                                              flowForthChartGap / 2
                                            }`}
                                            fill="transparent"
                                            stroke="grey"
                                            strokeWidth={4}
                                          />
                                        </svg>
                                      </>
                                    ))}
                                  </Card>
                                  <svg
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: 300,
                                      overflow: "visible",
                                      zIndex: 8,
                                      width: "200px",
                                    }}
                                  >
                                    <path
                                      d={`M 0 0 C 150 0 50 ${
                                        indexxes * flowThirdChartGap -
                                        (infant.children.length *
                                          flowThirdChartGap) /
                                          2 +
                                        flowThirdChartGap / 2
                                      } 200 ${
                                        indexxes * flowThirdChartGap -
                                        (infant.children.length *
                                          flowThirdChartGap) /
                                          2 +
                                        flowThirdChartGap / 2
                                      }`}
                                      fill="transparent"
                                      stroke="grey"
                                      strokeWidth={4}
                                    />
                                  </svg>
                                </>
                              ))}
                            </Card>
                            <svg
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: 300,
                                overflow: "visible",
                                zIndex: 8,
                                width: "200px",
                              }}
                            >
                              <path
                                d={`M 0 0 C 150 0 50 ${
                                  indexs * flowSecondChartGap -
                                  (child.children.length * flowSecondChartGap) /
                                    2 +
                                  flowSecondChartGap / 2
                                } 200 ${
                                  indexs * flowSecondChartGap -
                                  (child.children.length * flowSecondChartGap) /
                                    2 +
                                  flowSecondChartGap / 2
                                }`}
                                fill="transparent"
                                stroke="grey"
                                strokeWidth={4}
                              />
                            </svg>
                          </>
                        ))}
                      </Card>
                      <svg
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: 300,
                          overflow: "visible",
                          zIndex: 8,
                          width: "200px",
                        }}
                      >
                        <path
                          d={`M 0 0 C 150 0 50 ${
                            indexes * flowChartGap -
                            (first.children.length * flowChartGap) / 2 +
                            flowChartGap / 2
                          } 200 ${
                            indexes * flowChartGap -
                            (first.children.length * flowChartGap) / 2 +
                            flowChartGap / 2
                          }`}
                          fill="transparent"
                          stroke="grey"
                          strokeWidth={4}
                        />
                      </svg>
                    </>
                  ))}
                  {/* <svg
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "100px",
                      height: "100%",
                      width: "100%",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {first.children.map((child, index) => (
                      <path
                        key={index}
                        d={`M 0 0 Q 10 ${position[index] * 50} 100 ${
                          position[index] * 50
                        }`}
                        fill="transparent"
                        stroke="#ccc"
                      />
                    ))}
                  </svg> */}
                </Card>
              ))}
            </div>
          </Card>
        </>
      )}
      <div
        style={{
          width: "90%",
          fontSize: "28px",
          marginTop: "30px",
          color: "rgba(50,50,50)",
          fontWeight: "700",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: screenWidth < 800 ? "23px" : "28px",
            marginTop: "30px",
            color: "rgba(50,50,50)",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <DashboardOutlinedIcon
            style={{ fontSize: "40px", marginRight: "15px" }}
          />
          Expected Build Modules
        </div>
        {appJourneryEditable ? (
          <div>
            <Button
              onClick={() => {
                setCreateModuleModalIsOpen(true);
              }}
              className="button_small"
              style={{ borderRadius: "8px" }}
            >
              Add Modules
            </Button>
          </div>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: screenWidth < 800 ? "10px" : "20px",
          width: "90%",
          marginTop: "20px",
        }}
      >
        {buildModules.map((mod, index) => (
          <Card
            className="main_hover_appear"
            key={index}
            style={{
              width:
                windowsWidth <= 1000 ? "calc(50% - 5px)" : "calc(25% - 17px)",
              marginTop: "0px",
              borderRadius: "10px",
              padding: "30px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div>{PullIconForDisplay(mod.icon, index)}</div>
              <div
                style={{
                  position: "absolute",
                  top: 25,
                  right: 25,
                  rotate: "-90deg",
                }}
              >
                <ProgressCircular
                  value={mod.toughness}
                  color={getColor(index)}
                  style={{ position: "relative" }}
                >
                  {deletable ? (
                    <div
                      onClick={() => {
                        const updatingBuildModules = [...buildModules];
                        updatingBuildModules.splice(index, 1);
                        setBuildModules(updatingBuildModules);
                      }}
                      className="hover_appear"
                      style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        opacity: 0,
                        transition: "0.3s all ease-in-out",
                      }}
                    >
                      <CloseIcon />
                    </div>
                  ) : appJourneryEditable ? (
                    <div
                      onClick={() => {
                        setCreateModuleModalIsOpen(true);
                        console.log(mod);
                        setInputModules(mod);
                      }}
                      className="hover_appear"
                      style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        opacity: 0,
                        transition: "0.3s all ease-in-out",
                      }}
                    >
                      <BlurOnIcon />
                    </div>
                  ) : null}
                </ProgressCircular>
              </div>
              <div
                style={{
                  color: "rgba(100,100,100)",
                  fontSize: screenWidth < 800 ? "15px" : "25px",
                  marginTop: "15px",
                  fontWeight: 600,
                }}
              >
                {mod.title}
              </div>
              <div
                style={{
                  color: "rgba(100,100,100)",
                  fontSize: screenWidth < 800 ? "10px" : "15px",
                  marginTop: "5px",
                }}
              >
                {mod.description}
              </div>
            </div>
            <div
              style={{
                color: "rgba(140,140,140)",
                fontSize: screenWidth < 800 ? "10px" : "13px",
                fontWeight: 500,
                marginTop: "5px",
                marginTop: "15px",
                display: "flex",
                gap: "6px",
              }}
            >
              <div>Development cost:</div>
              <div>
                {currency +
                  " " +
                  ((mod.toughness * 20000) / 100).toLocaleString("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </div>
            </div>
          </Card>
        ))}
      </div>
      <div
        style={{
          width: "90%",
          textAlign: "right",
          fontSize: screenWidth < 800 ? "22px" : "28px",
          color: "rgba(50,50,50)",
          fontWeight: "700",
          marginTop: "20px",
          display: "flex",
          gap: "10px",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            fontSize: screenWidth < 800 ? "13px" : "19px",
            color: "rgba(120,120,120)",
            marginBottom: "3px",
          }}
        >
          Total Development Cost:
        </div>
        {currency +
          " " +
          getTotalDevelopmentCost(buildModules).toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
      </div>
      <div
        style={{
          width: "90%",
          fontSize: screenWidth < 800 ? "23px" : "28px",
          marginTop: "30px",
          color: "rgba(50,50,50)",
          fontWeight: "700",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TimelineOutlinedIcon
          style={{ fontSize: "40px", marginRight: "15px" }}
        />
        Development Timeline
      </div>
      <div style={{ display: "flex", width: "90%", flexWrap: "wrap" }}>
        {moduleTimeline.map((tim, index) => (
          <div
            key={index}
            style={{
              width: windowsWidth <= 1000 ? "50%" : "20%",
              marginTop: windowsWidth <= 1000 && index >= 2 ? "60px" : "30px",
            }}
          >
            <div
              style={{
                width: "100%",
                marginBottom: "20px",
                position: "relative",
              }}
            >
              <Card
                style={{
                  width: "70px",
                  height: "70px",
                  marginLeft: "calc(50% - 35px)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: 600,
                  fontSize: "40px",
                  color: "rgba(100,100,100)",
                }}
              >
                {index + 1}
              </Card>
              {windowsWidth <= 1000 && index % 2 ? null : (
                <div
                  style={{
                    position: "absolute",
                    width: "80%",
                    marginLeft: "84%",
                    color: "lightgrey",
                    top: 30,
                    height: "30px",
                  }}
                >
                  <div
                    className="p-checkout-process-row-bar-2"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      height: "15px",
                      width: "45%",
                      borderRadius: "20px",
                    }}
                  >
                    <div
                      className="animation_class p-checkout-process-row-inner-bar-2"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        height: "15px",
                        borderRadius: "20px",
                      }}
                    />
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                  fontSize: screenWidth < 800 ? "22px" : "28px",
                  fontWeight: 700,
                  color: "rgba(100,100,100)",
                }}
              >
                {"Stage " + (index + 1)}
              </div>
            </div>
            <Card inset style={{ width: "90%", marginLeft: "5%" }}>
              {tim.map((item, indexs) => (
                <div
                  key={indexs}
                  style={{
                    width: "90%",
                    textAlign: "center",
                    marginLeft: "5%",
                    fontSize: "13px",
                    marginTop: "5px",
                  }}
                >
                  {item.title}
                </div>
              ))}
            </Card>
            <div
              style={{
                color: "rgba(140,140,140)",
                fontSize: screenWidth < 800 ? "10px" : "15px",
                marginTop: "5px",
                fontWeight: 500,
                marginTop: "15px",
                gap: "6px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <div>Estimated Development Time</div>
              <div>{formatDuration(buildStageTimeline[index].duration)}</div>
            </div>
            <div
              style={{
                color: "rgba(140,140,140)",
                fontSize: screenWidth < 800 ? "10px" : "15px",
                marginTop: "5px",
                fontWeight: 500,
                marginTop: "15px",
                gap: "6px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <div>Estimated Completion</div>
              <div>
                {convertDateFormat(
                  calculateEndDate(startDate, buildStageTimeline, index)
                )}
              </div>
            </div>
            <div
              style={{
                color: "rgba(140,140,140)",
                fontSize: screenWidth < 800 ? "10px" : "15px",
                fontWeight: 500,
                marginTop: "5px",
                marginTop: "15px",
                gap: "6px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <div>Estimated Payment</div>
              <div>
                {currency +
                  " " +
                  (
                    getTotalDevelopmentCost(buildModules) /
                    (moduleTimeline.length + 1)
                  ).toLocaleString("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </div>
            </div>
          </div>
        ))}
        <div
          style={{
            width: windowsWidth <= 1000 ? "50%" : "20%",
            marginTop: windowsWidth <= 1000 ? "60px" : "30px",
          }}
        >
          <div
            style={{
              width: "100%",
              marginBottom: "20px",
              position: "relative",
            }}
          >
            <Card
              style={{
                width: "70px",
                height: "70px",
                marginLeft: "calc(50% - 35px)",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontWeight: 600,
                fontSize: "40px",
                color: "rgba(100,100,100)",
              }}
            >
              {moduleTimeline.length + 1}
            </Card>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                fontSize: screenWidth < 800 ? "22px" : "28px",
                fontWeight: 700,
                color: "rgba(100,100,100)",
              }}
            >
              UAT
            </div>
          </div>
          <Card inset style={{ width: "90%", marginLeft: "5%" }}>
            <div
              style={{
                width: "90%",
                textAlign: "center",
                marginLeft: "5%",
                fontSize: "13px",
              }}
            >
              A stage for User Acceptance Testing
            </div>
          </Card>
          <div
            style={{
              color: "rgba(140,140,140)",
              fontSize: screenWidth < 800 ? "10px" : "15px",
              marginTop: "5px",
              fontWeight: 500,
              marginTop: "15px",
              gap: "6px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <div>Estimated Development Time</div>
            <div>1 month</div>
          </div>
          <div
            style={{
              color: "rgba(140,140,140)",
              fontSize: screenWidth < 800 ? "10px" : "15px",
              fontWeight: 500,
              marginTop: "5px",
              marginTop: "15px",
              gap: "6px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <div>Estimated Payment</div>
            <div>
              {currency +
                " " +
                (
                  getTotalDevelopmentCost(buildModules) /
                  (moduleTimeline.length + 1)
                ).toLocaleString("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          textAlign: "right",
          fontSize: screenWidth < 800 ? "22px" : "28px",
          color: "rgba(50,50,50)",
          fontWeight: "700",
          marginTop: "30px",
          display: "flex",
          gap: "10px",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            fontSize: screenWidth < 800 ? "13px" : "19px",
            color: "rgba(120,120,120)",
            marginBottom: "3px",
          }}
        >
          Total Development Timeline:
        </div>
        {formatDuration(getTotalDevelopmentTime(buildModules))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "30px",
          width: "90%",
        }}
      >
        <div
          style={{
            fontSize: screenWidth < 800 ? "23px" : "28px",
            color: "rgba(50,50,50)",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <PaymentIcon style={{ fontSize: "40px", marginRight: "15px" }} />
          Accounting To-Date
        </div>
        {appJourneryEditable ? (
          <div>
            <Button
              onClick={() => {
                setCreateTransationsModalIsOpen(true);
              }}
              className="button_small"
              style={{ borderRadius: "8px" }}
            >
              Add Transations
            </Button>
          </div>
        ) : null}
      </div>
      <div
        style={{
          marginTop: "20px",
          width: "90%",
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {transactions.length > 0 ? (
          transactions.map((trans, index) => (
            <Card
              key={index}
              style={{
                width:
                  windowsWidth <= 1000
                    ? "calc(50% - 18px)"
                    : "calc(25% - 18px)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "rgba(0,0,0,0.6)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div>
                  <LocalAtmIcon
                    style={{
                      fontSize: "90px",
                      color: "rgba(0,0,0,0.5)",
                      marginBottom: "10px",
                    }}
                  />
                </div>
                <div style={{ fontSize: "20px", marginTop: "-10px" }}>
                  {currency +
                    " " +
                    parseFloat(trans.amount).toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </div>
                <div style={{ fontSize: "12px", marginTop: "10px" }}>
                  {trans.transaction_notes}
                </div>
                <div style={{ fontSize: "12px" }}>{formatDate(trans.date)}</div>
              </div>
            </Card>
          ))
        ) : (
          <Card style={{ width: "100%" }}>
            <div
              style={{
                padding: "40px 0px",
                display: "flex",
                justifyContent: "center",
                color: "rgba(0,0,0,0.3)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <SavingsIcon
                style={{
                  fontSize: "90px",
                  color: "rgba(0,0,0,0.2)",
                  marginBottom: "10px",
                }}
              />
              There is currently no received <br /> transactions
            </div>
          </Card>
        )}
      </div>
      <div
        style={{
          width: "90%",
          fontSize: screenWidth < 800 ? "23px" : "28px",
          marginTop: "60px",
          color: "rgba(50,50,50)",
          fontWeight: "700",
          display: "flex",
          alignItems: "center",
        }}
      >
        <MessageIcon style={{ fontSize: "40px", marginRight: "15px" }} />
        Development Updates
      </div>
      <Card
        style={{
          width: "90%",
          marginTop: "20px",
          padding: "20px",
          height: "70vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          className="adjusted_scroll_bar"
          ref={scrollContainerRef}
          style={{
            width: "100%",
            height: "calc(70vh - 100px)",
            overflowY: "auto",
          }}
        >
          {messages.map((mes, index) => (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: mes.from === "dev" ? "flex-end" : "flex-start",
                marginTop: "15px",
              }}
            >
              <Card
                inset
                style={{
                  maxWidth: "70%",
                  minWidth: "30%",
                  background:
                    mes.from === "dev"
                      ? "rgba(255,255,255,0.2)"
                      : "rgba(79, 130, 93,0.1)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: screenWidth < 800 ? "8px" : "12px",
                    opacity: "0.5",
                  }}
                >
                  <div style={{}}>
                    {mes.from === "dev" ? "Project Developer" : clientName}
                  </div>
                  <div>{convertDateFormat2(mes.date)}</div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    color: "rgba(0,0,0,0.7)",
                    fontSize: screenWidth < 800 ? "12px" : "15px",
                  }}
                >
                  {mes.message}
                </div>
              </Card>
            </div>
          ))}
        </div>
        <Card
          inset
          key={key}
          style={{
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <form
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              if (messageToSend !== "") {
                const messageToPush = {
                  from: admin ? "dev" : "user",
                  date: new Date(),
                  message: messageToSend,
                };
                const updatingMessage = [...messages, messageToPush];
                setMessages(updatingMessage);
                setMessageToSend("");
                setKey(key + 1);
                sendMessageToDB(updatingMessage);
              }
            }}
          >
            <input
              className="project_details_text_field"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                width: "97%",
                fontSize: screenWidth < 800 ? "12px" : "15px",
              }}
              width="400px"
              placeholder="Type your message here"
              value={messageToSend || ""}
              onChange={(e) => {
                setMessageToSend(e.target.value);
              }}
            />
            <div
              className="add_pointer"
              onClick={() => {
                if (messageToSend !== "") {
                  const messageToPush = {
                    from: admin ? "dev" : "user",
                    date: new Date(),
                    message: messageToSend,
                  };
                  const updatingMessage = [...messages, messageToPush];
                  setMessages(updatingMessage);
                  setMessageToSend("");
                  setKey(key + 1);
                  sendMessageToDB(updatingMessage);
                }
              }}
              style={{
                width: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SendIcon style={{ color: "rgba(120,120,120)" }} />
            </div>
          </form>
        </Card>
      </Card>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "90%",
          position: "relative",
          textAlign: "center",
          marginTop: "30px",
          fontSize: "13px",
          color: "rgba(0,0,0,0.3)",
          paddingBottom: "20px",
        }}
      >
        <div>All Rights Reserved © Jonathan Gan</div>
        <div>Gavin Labs 2023</div>
      </div>

      <Dialog
        visible={createModuleModalIsOpen}
        onClose={() => setCreateModuleModalIsOpen(false)}
      >
        <Card style={{ minWidth: "500px" }}>
          <div
            style={{
              fontSize: "28px",
              color: "rgba(50,50,50)",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
          >
            Create Build Modules
          </div>
          <div style={{ margin: "20px 0px -20px 0px" }}>
            {inputModules.icon
              ? PullIconForDisplay1(inputModules.icon, 0)
              : null}
          </div>
          <form>
            <TextField
              className="module_text_field"
              required
              style={{ margin: "30px 0px 5px 0px" }}
              width="400px"
              label="Title"
              value={inputModules.title || ""}
              onChange={(e) => {
                setInputModules({ ...inputModules, title: e.value });
              }}
            />
            <TextField
              className="module_text_field"
              required
              style={{ margin: "5px 0px 5px 0px" }}
              width="400px"
              label="Description"
              value={inputModules.description || ""}
              onChange={(e) => {
                setInputModules({ ...inputModules, description: e.value });
              }}
            />
            <TextField
              className="module_text_field"
              required
              style={{ margin: "5px 0px 5px 0px" }}
              width="400px"
              label="Toughness"
              value={inputModules.toughness || ""}
              onChange={(e) => {
                setInputModules({ ...inputModules, toughness: e.value });
              }}
            />
            <TextField
              className="module_text_field"
              required
              style={{ margin: "5px 0px 5px 0px" }}
              width="400px"
              label="Weeks of development"
              value={inputModules.timeline || ""}
              onChange={(e) => {
                setInputModules({ ...inputModules, timeline: e.value });
              }}
            />
            <TextField
              className="module_text_field"
              style={{ margin: "5px 0px 5px 0px" }}
              width="400px"
              label="Search icons"
              value={iconsSearchTerm}
              onChange={(e) => {
                setIconSearchTerm(e.value);
              }}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "450px",
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {iconsSearchTerm === ""
                ? iconNames.slice(0, 153).map((iconName) => {
                    const Icon = iconLibrary[iconName];
                    return (
                      <div
                        key={iconName}
                        onClick={() => {
                          setInputModules({ ...inputModules, icon: iconName });
                        }}
                        style={{
                          cursor: "pointer",
                          padding: "5px",
                        }}
                      >
                        <Icon
                          style={{ fontSize: 35, color: "rgba(65,65,65)" }}
                        />
                      </div>
                    );
                  })
                : iconNames
                    .filter((iconName) =>
                      iconName
                        .toLowerCase()
                        .includes(iconsSearchTerm.toLowerCase())
                    )
                    .map((iconName) => {
                      const Icon = iconLibrary[iconName];
                      return (
                        <div
                          key={iconName}
                          onClick={() => {
                            setInputModules({
                              ...inputModules,
                              icon: iconName,
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            padding: "5px",
                          }}
                        >
                          <Icon
                            style={{ fontSize: 35, color: "rgba(65,65,65)" }}
                          />
                        </div>
                      );
                    })}
            </div>
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button onClick={() => setCreateModuleModalIsOpen(false)}>
                  Close
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    const buildModuleToUpdate = [...buildModules];
                    if (inputModules.id) {
                      const moduleIndex = buildModules.findIndex(
                        (mod) => mod.id === inputModules.id
                      );
                      buildModuleToUpdate[moduleIndex] = inputModules;
                    } else {
                      const moduleToPush = { ...inputModules, id: new Date() };
                      buildModuleToUpdate.push(moduleToPush);
                    }
                    setBuildModules(buildModuleToUpdate);
                    setInputModules({
                      icon: "",
                      title: "",
                      description: "",
                      toughness: 0,
                    });
                    setCreateModuleModalIsOpen(false);
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Dialog>

      <Dialog
        visible={createTransationsModalIsOpen}
        onClose={() => setCreateTransationsModalIsOpen(false)}
      >
        <Card style={{ minWidth: "500px" }}>
          <div
            style={{
              fontSize: "28px",
              color: "rgba(50,50,50)",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
            }}
          >
            Create Transaction
          </div>
          <form>
            <TextField
              className="module_text_field"
              required
              style={{ margin: "30px 0px 5px 0px" }}
              width="400px"
              label="Amount received"
              value={inputTransaction.amount || ""}
              onChange={(e) => {
                setInputTransaction({ ...inputTransaction, amount: e.value });
              }}
            />
            <TextField
              className="module_text_field"
              required
              style={{ margin: "5px 0px 5px 0px" }}
              width="400px"
              label="Transactions details"
              value={inputTransaction.transaction_notes || ""}
              onChange={(e) => {
                setInputTransaction({
                  ...inputTransaction,
                  transaction_notes: e.value,
                });
              }}
            />
            <input
              className="module_text_field"
              type="date"
              required
              style={{
                margin: "5px 0px 5px 0px",
                padding: "5px 10px",
                border: "none",
                outline: "none",
                background: "rgb(228, 235, 245)",
                color: "rgba(100,100,100)",
              }}
              width="400px"
              value={inputTransaction.date || ""}
              onChange={(e) => {
                setInputTransaction({
                  ...inputTransaction,
                  date: e.target.value,
                });
              }}
            />
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button onClick={() => setCreateTransationsModalIsOpen(false)}>
                  Close
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setTransactions([...transactions, { ...inputTransaction }]);
                    setInputTransaction({
                      amount: "",
                      date: new Date(),
                      transaction_notes: "",
                    });
                    setCreateTransationsModalIsOpen(false);
                  }}
                >
                  Create
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Dialog>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 500,
          },
          content: {
            position: "absolute",
            zIndex: 1000,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "50%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
            transition: "0.3s all ease-in-out",
          },
        }}
      ></Modal> */}
    </div>
  );
};

export default Homepage;
