import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import Homepage from './Homepage';
import Website from './Website';
import Sample from './Sample';

function App() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<Website />} />
        <Route path="/sample" element={<Sample />} />
        <Route path="/project" element={<Homepage />} />
        <Route path="/homepage" element={<Navigate to="/" />} />
        <Route path="/home" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
